import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

const CollectionAddBulkDialog = props => {
  const {
    onClose,
    open,
    totalFiguresAdded,
    totalAccessoriesAdded,
    setTotalFiguresAdded,
    setTotalAccessoriesAdded,
    setTotalAccessoriesWithFigsAdded
  } = props

  let navigate = useNavigate()

  const resetInputs = () => {
    onClose()
    setTotalAccessoriesAdded([])
    setTotalFiguresAdded([])
    setTotalAccessoriesWithFigsAdded([])
  }

  const handleClose = () => {
    resetInputs()
  }

  function handleGoToCollection () {
    resetInputs()
    navigate('/collection')
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Added Items to Collection</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>
          We&rsquo;ve added {totalFiguresAdded.length} figures and{' '}
          {totalAccessoriesAdded.length} accessories to the Collection
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Add More Items</Button>
        <Button onClick={handleGoToCollection}>View Collection</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CollectionAddBulkDialog
