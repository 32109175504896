import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import ConfirmAccountForm from '../../components/ConfirmAccountForm/ConfirmAccountForm'

import Box from '@mui/material/Box'
import { alpha } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import useBodyClass from '../../hooks/useBodyClass'
import { useUser } from '../../hooks/useUser'

import { Footer } from '../../components/Footer'

const ConfirmAccountPage = () => {
  useBodyClass('o96-bg--space')
  const location = useLocation()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { user } = useUser()
  const passedEmail = location.state?.userEmail

  if (user?.username) {
    return <Navigate to='/' replace />
  }

  return (
    <>
      <div
        className={`o96-body o96-body--with-centered-footer ${
          isMobile ? 'o96-body--mobile' : ''
        }`}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              mt: 5,
              px: 4,
              pt: 2,
              pb: 4,
              backgroundColor: theme =>
                alpha(theme.palette.background.default, 0.9),
              border: 1,
              borderRadius: 2,
              borderColor: theme => theme.palette.divider,
              maxWidth: 550
            }}
          >
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              align='center'
              sx={{
                mb: { xs: 3, md: 1 }
              }}
            >
              {'Confirm Account'}
            </Typography>
            {!isMobile ? (
              <Typography
                variant='body2'
                align='center'
                sx={{
                  px: 3,
                  mb: 3
                }}
              >
                We've sent you an email with a verification code. Please enter
                it below to confirm your account, then you can sign in.
              </Typography>
            ) : (
              ''
            )}

            <ConfirmAccountForm passedEmail={passedEmail} />
          </Box>
        </Container>
      </div>
      <Footer theme={`centered`} />
    </>
  )
}

export default ConfirmAccountPage
