import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import useBodyClass from '../../hooks/useBodyClass'

import { Footer } from '../../components/Footer'

const DisclaimerPage = () => {
  useBodyClass('o96-bg--space')

  return (
    <>
      <div className='o96-body o96-body--with-centered-footer'>
        <Container
          sx={{
            paddingTop: '2rem'
          }}
        >
          <Box
            className='o96-box--main'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pb: '2rem'
            }}
          >
            <AppBar
              position='static'
              sx={{
                borderRadius: '.4875rem .4875rem 0 0',
                display: 'flex',
                alignItems: 'center',
                padding: '.75rem 0'
              }}
            >
              <Typography variant='h5' component='div'>
                Disclaimer
              </Typography>
            </AppBar>
            <div style={{ textAlign: 'left', padding: '0 12rem' }}>
              <p>Last updated: May 04, 2022</p>
              <Typography variant='h4' sx={{ mt: 3, mb: 3 }}>
                Interpretation and Definitions
              </Typography>
              <Typography variant='h5'>Interpretation</Typography>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
              <Typography variant='h5'>Definitions</Typography>
              <p>For the purposes of this Disclaimer:</p>
              <ul>
                <li>
                  <strong>Company</strong> (referred to as either &quot;the
                  Company&quot;, &quot;We&quot;, &quot;Us&quot; or
                  &quot;Our&quot; in this Disclaimer) refers to Original96 LLC,
                  5305 River Rd N Suite B, Keizer OR, 97303.
                </li>
                <li>
                  <strong>Service</strong> refers to the Website.
                </li>
                <li>
                  <strong>You</strong> means the individual accessing the
                  Service, or the company, or other legal entity on behalf of
                  which such individual is accessing or using the Service, as
                  applicable.
                </li>
                <li>
                  <strong>Website</strong> refers to Original96, accessible from{' '}
                  <a
                    href='https://www.original96.com'
                    rel='noreferrer'
                    target='_blank'
                  >
                    https://www.original96.com
                  </a>
                </li>
              </ul>
              <Typography variant='h4' sx={{ mt: 3, mb: 3 }}>
                Disclaimer
              </Typography>
              <p>
                The information contained on the Service is for general
                information purposes only.
              </p>
              <p>
                The Company assumes no responsibility for errors or omissions in
                the contents of the Service.
              </p>
              <p>
                In no event shall the Company be liable for any special, direct,
                indirect, consequential, or incidental damages or any damages
                whatsoever, whether in an action of contract, negligence or
                other tort, arising out of or in connection with the use of the
                Service or the contents of the Service. The Company reserves the
                right to make additions, deletions, or modifications to the
                contents on the Service at any time without prior notice.
              </p>
              <p>
                The Company does not warrant that the Service is free of viruses
                or other harmful components.
              </p>
              <Typography variant='h4' sx={{ mt: 3, mb: 3 }}>
                FTC Affiliate Disclaimer
              </Typography>
              <p>
                The disclosure that follows is intended to fully comply with the
                Federal Trade Commission's policy of the United States that
                requires the Company to be transparent about any and all
                affiliate relations the Company may have on the Service.
              </p>
              <p>
                You should assume that some of the links are &quot;affiliate
                links&quot;, a link with a special tracking code.
              </p>
              <p>
                This means that if You click on an affiliate link and purchase
                the item, the Company may receive an affiliate commission. This
                is a legitimate way to monetize and pay for the operation of the
                Service and the Company gladly reveal its affiliate
                relationships to You.
              </p>
              <p>
                The price of the item is the same whether it is an affiliate
                link or not. Regardless, the Company only recommends products or
                services the Company believes will add value to users.
              </p>
              <p>Affiliate advertising programs that the Service uses are:</p>
              <ul>
                <li>eBay Partner Network</li>
              </ul>
              <Typography variant='h4' sx={{ mt: 3, mb: 3 }}>
                External Links Disclaimer
              </Typography>
              <p>
                The Service may contain links to external websites that are not
                provided or maintained by or in any way affiliated with the
                Company.
              </p>
              <p>
                Please note that the Company does not guarantee the accuracy,
                relevance, timeliness, or completeness of any information on
                these external websites.
              </p>
              <Typography variant='h4' sx={{ mt: 3, mb: 3 }}>
                Errors and Omissions Disclaimer
              </Typography>
              <p>
                The information given by the Service is for general guidance on
                matters of interest only. Even if the Company takes every
                precaution to insure that the content of the Service is both
                current and accurate, errors can occur. Plus, given the changing
                nature of laws, rules and regulations, there may be delays,
                omissions or inaccuracies in the information contained on the
                Service.
              </p>
              <p>
                The Company is not responsible for any errors or omissions, or
                for the results obtained from the use of this information.
              </p>
              <Typography variant='h4' sx={{ mt: 3, mb: 3 }}>
                Fair Use Disclaimer
              </Typography>
              <p>
                The Company may use copyrighted material which has not always
                been specifically authorized by the copyright owner. The Company
                is making such material available for criticism, comment, news
                reporting, teaching, scholarship, or research.
              </p>
              <p>
                The Company believes this constitutes a &quot;fair use&quot; of
                any such copyrighted material as provided for in section 107 of
                the United States Copyright law.
              </p>
              <p>
                If You wish to use copyrighted material from the Service for
                your own purposes that go beyond fair use, You must obtain
                permission from the copyright owner.
              </p>
              <Typography variant='h4' sx={{ mt: 3, mb: 3 }}>
                Views Expressed Disclaimer
              </Typography>
              <p>
                The Service may contain views and opinions which are those of
                the authors and do not necessarily reflect the official policy
                or position of any other author, agency, organization, employer
                or company, including the Company.
              </p>
              <p>
                Comments published by users are their sole responsibility and
                the users will take full responsibility, liability and blame for
                any libel or litigation that results from something written in
                or as a direct result of something written in a comment. The
                Company is not liable for any comment published by users and
                reserves the right to delete any comment for any reason
                whatsoever.
              </p>
              <Typography variant='h4' sx={{ mt: 3, mb: 3 }}>
                No Responsibility Disclaimer
              </Typography>
              <p>
                The information on the Service is provided with the
                understanding that the Company is not herein engaged in
                rendering legal, accounting, tax, or other professional advice
                and services. As such, it should not be used as a substitute for
                consultation with professional accounting, tax, legal or other
                competent advisers.
              </p>
              <p>
                In no event shall the Company or its suppliers be liable for any
                special, incidental, indirect, or consequential damages
                whatsoever arising out of or in connection with your access or
                use or inability to access or use the Service.
              </p>
              <Typography variant='h4' sx={{ mt: 3, mb: 3 }}>
                &quot;Use at Your Own Risk&quot; Disclaimer
              </Typography>
              <p>
                All information in the Service is provided &quot;as is&quot;,
                with no guarantee of completeness, accuracy, timeliness or of
                the results obtained from the use of this information, and
                without warranty of any kind, express or implied, including, but
                not limited to warranties of performance, merchantability and
                fitness for a particular purpose.
              </p>
              <p>
                The Company will not be liable to You or anyone else for any
                decision made or action taken in reliance on the information
                given by the Service or for any consequential, special or
                similar damages, even if advised of the possibility of such
                damages.
              </p>
              <Typography variant='h4' sx={{ mt: 3, mb: 3 }}>
                Contact Us
              </Typography>
              <p>
                If you have any questions about this Disclaimer, You can contact
                Us:
              </p>
              <ul>
                <li>By email: help@original96.com</li>
              </ul>
            </div>
          </Box>
        </Container>
      </div>
      <div style={{ padding: '0 0 .5rem', width: '100%' }}>
        <Footer theme={`centered`} />
      </div>
    </>
  )
}

export default DisclaimerPage
