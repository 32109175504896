import { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { listCollections, getCollection } from '../graphql/queries'

export const useGetCollection = collectionRefetch => {
  const [status, setStatus] = useState('idle')
  const [collection, setCollection] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setStatus('fetching')
        const collectionsListData = await API.graphql({
          query: listCollections
        })
        if (collectionsListData?.data.listCollections.items.length === 1) {
          const collectionID = await collectionsListData.data.listCollections
            .items[0].id
          const collectionData = await API.graphql(
            graphqlOperation(getCollection, {
              id: collectionID
            })
          )
          const collectionItems = await collectionData.data.getCollection
          setCollection(collectionItems)
          setStatus('fetched')
        } else {
          setStatus('noCollectionAdded')
        }
      } catch (e) {
        console.log(e)
        setStatus('noUserFound')
      }
    }
    fetchData()
  }, [collectionRefetch])

  return { status, collection, setStatus }
}
