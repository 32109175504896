/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      collection
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      collection
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      collection
      createdAt
      updatedAt
    }
  }
`;
export const createCompleteFigure = /* GraphQL */ `
  mutation CreateCompleteFigure(
    $input: CreateCompleteFigureInput!
    $condition: ModelCompleteFigureConditionInput
  ) {
    createCompleteFigure(input: $input, condition: $condition) {
      id
      keyName
      name
      accessories {
        keyName
        name
        id
        shortName
        linkPath
        ebayLink
      }
      accessoryVariants {
        keyName
        name
        id
      }
      ebayLink
      variantName
      variantType
      initialReleaseYear
      currentRelease
      linkPath
      hexColor
      textColor
      initialReleaseWave
      initialReleaseWaveLabel
      shortName
      inStories {
        items {
          id
          firstAppearedIn
          affiliation
          species
          createdAt
          updatedAt
          completeFigureInStoriesId
        }
        nextToken
      }
      likes {
        items {
          id
          email
          createdAt
          updatedAt
          completeFigureLikesId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompleteFigure = /* GraphQL */ `
  mutation UpdateCompleteFigure(
    $input: UpdateCompleteFigureInput!
    $condition: ModelCompleteFigureConditionInput
  ) {
    updateCompleteFigure(input: $input, condition: $condition) {
      id
      keyName
      name
      accessories {
        keyName
        name
        id
        shortName
        linkPath
        ebayLink
      }
      accessoryVariants {
        keyName
        name
        id
      }
      ebayLink
      variantName
      variantType
      initialReleaseYear
      currentRelease
      linkPath
      hexColor
      textColor
      initialReleaseWave
      initialReleaseWaveLabel
      shortName
      inStories {
        items {
          id
          firstAppearedIn
          affiliation
          species
          createdAt
          updatedAt
          completeFigureInStoriesId
        }
        nextToken
      }
      likes {
        items {
          id
          email
          createdAt
          updatedAt
          completeFigureLikesId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompleteFigure = /* GraphQL */ `
  mutation DeleteCompleteFigure(
    $input: DeleteCompleteFigureInput!
    $condition: ModelCompleteFigureConditionInput
  ) {
    deleteCompleteFigure(input: $input, condition: $condition) {
      id
      keyName
      name
      accessories {
        keyName
        name
        id
        shortName
        linkPath
        ebayLink
      }
      accessoryVariants {
        keyName
        name
        id
      }
      ebayLink
      variantName
      variantType
      initialReleaseYear
      currentRelease
      linkPath
      hexColor
      textColor
      initialReleaseWave
      initialReleaseWaveLabel
      shortName
      inStories {
        items {
          id
          firstAppearedIn
          affiliation
          species
          createdAt
          updatedAt
          completeFigureInStoriesId
        }
        nextToken
      }
      likes {
        items {
          id
          email
          createdAt
          updatedAt
          completeFigureLikesId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createStory = /* GraphQL */ `
  mutation CreateStory(
    $input: CreateStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    createStory(input: $input, condition: $condition) {
      id
      firstAppearedIn
      affiliation
      species
      createdAt
      updatedAt
      completeFigureInStoriesId
    }
  }
`;
export const updateStory = /* GraphQL */ `
  mutation UpdateStory(
    $input: UpdateStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    updateStory(input: $input, condition: $condition) {
      id
      firstAppearedIn
      affiliation
      species
      createdAt
      updatedAt
      completeFigureInStoriesId
    }
  }
`;
export const deleteStory = /* GraphQL */ `
  mutation DeleteStory(
    $input: DeleteStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    deleteStory(input: $input, condition: $condition) {
      id
      firstAppearedIn
      affiliation
      species
      createdAt
      updatedAt
      completeFigureInStoriesId
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      completeFigureLikesId
      owner
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      completeFigureLikesId
      owner
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      completeFigureLikesId
      owner
    }
  }
`;
export const createCollection = /* GraphQL */ `
  mutation CreateCollection(
    $input: CreateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    createCollection(input: $input, condition: $condition) {
      id
      figures {
        items {
          id
          keyName
          maker
          condition
          note
          createdAt
          updatedAt
          collectionFiguresId
          owner
        }
        nextToken
      }
      accessories {
        items {
          id
          keyName
          name
          maker
          condition
          note
          figureAccessoriesId
          createdAt
          updatedAt
          collectionAccessoriesId
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCollection = /* GraphQL */ `
  mutation UpdateCollection(
    $input: UpdateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    updateCollection(input: $input, condition: $condition) {
      id
      figures {
        items {
          id
          keyName
          maker
          condition
          note
          createdAt
          updatedAt
          collectionFiguresId
          owner
        }
        nextToken
      }
      accessories {
        items {
          id
          keyName
          name
          maker
          condition
          note
          figureAccessoriesId
          createdAt
          updatedAt
          collectionAccessoriesId
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCollection = /* GraphQL */ `
  mutation DeleteCollection(
    $input: DeleteCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    deleteCollection(input: $input, condition: $condition) {
      id
      figures {
        items {
          id
          keyName
          maker
          condition
          note
          createdAt
          updatedAt
          collectionFiguresId
          owner
        }
        nextToken
      }
      accessories {
        items {
          id
          keyName
          name
          maker
          condition
          note
          figureAccessoriesId
          createdAt
          updatedAt
          collectionAccessoriesId
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFigure = /* GraphQL */ `
  mutation CreateFigure(
    $input: CreateFigureInput!
    $condition: ModelFigureConditionInput
  ) {
    createFigure(input: $input, condition: $condition) {
      id
      keyName
      maker
      condition
      note
      createdAt
      accessories {
        items {
          id
          keyName
          name
          maker
          condition
          note
          figureAccessoriesId
          createdAt
          updatedAt
          collectionAccessoriesId
          owner
        }
        nextToken
      }
      updatedAt
      collectionFiguresId
      owner
    }
  }
`;
export const updateFigure = /* GraphQL */ `
  mutation UpdateFigure(
    $input: UpdateFigureInput!
    $condition: ModelFigureConditionInput
  ) {
    updateFigure(input: $input, condition: $condition) {
      id
      keyName
      maker
      condition
      note
      createdAt
      accessories {
        items {
          id
          keyName
          name
          maker
          condition
          note
          figureAccessoriesId
          createdAt
          updatedAt
          collectionAccessoriesId
          owner
        }
        nextToken
      }
      updatedAt
      collectionFiguresId
      owner
    }
  }
`;
export const deleteFigure = /* GraphQL */ `
  mutation DeleteFigure(
    $input: DeleteFigureInput!
    $condition: ModelFigureConditionInput
  ) {
    deleteFigure(input: $input, condition: $condition) {
      id
      keyName
      maker
      condition
      note
      createdAt
      accessories {
        items {
          id
          keyName
          name
          maker
          condition
          note
          figureAccessoriesId
          createdAt
          updatedAt
          collectionAccessoriesId
          owner
        }
        nextToken
      }
      updatedAt
      collectionFiguresId
      owner
    }
  }
`;
export const createAccessory = /* GraphQL */ `
  mutation CreateAccessory(
    $input: CreateAccessoryInput!
    $condition: ModelAccessoryConditionInput
  ) {
    createAccessory(input: $input, condition: $condition) {
      id
      keyName
      name
      maker
      condition
      note
      figureAccessoriesId
      createdAt
      updatedAt
      collectionAccessoriesId
      owner
    }
  }
`;
export const updateAccessory = /* GraphQL */ `
  mutation UpdateAccessory(
    $input: UpdateAccessoryInput!
    $condition: ModelAccessoryConditionInput
  ) {
    updateAccessory(input: $input, condition: $condition) {
      id
      keyName
      name
      maker
      condition
      note
      figureAccessoriesId
      createdAt
      updatedAt
      collectionAccessoriesId
      owner
    }
  }
`;
export const deleteAccessory = /* GraphQL */ `
  mutation DeleteAccessory(
    $input: DeleteAccessoryInput!
    $condition: ModelAccessoryConditionInput
  ) {
    deleteAccessory(input: $input, condition: $condition) {
      id
      keyName
      name
      maker
      condition
      note
      figureAccessoriesId
      createdAt
      updatedAt
      collectionAccessoriesId
      owner
    }
  }
`;
