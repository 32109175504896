import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { useGetFigures } from '../../hooks/useGetFigures'

const FigureSideNav = () => {
  const { completeFigures } = useGetFigures()

  const location = useLocation()
  const path = location.pathname.replace('/figures/', '')
  const [value, setValue] = React.useState(path)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <nav className='o96-side-nav'>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Figure Navigation'
      >
        {completeFigures.map(({ name, subName, linkPath, variantName }, i) => {
          return (
            <Tab
              component={Link}
              key={i}
              to={'/figures/' + linkPath}
              value={linkPath}
              label={
                <>
                  {name}
                  {/*{variantName && (
                    <>
                      <br />
                      <span style={{ fontSize: 'smaller' }}>{variantName}</span>
                    </>
                  )}
                  {subName && (
                      <>
                        <br />
                        <span style={{ fontSize: 'smaller' }}>{subName}</span>
                      </>
                    )} */}
                </>
              }
            />
          )
        })}
      </Tabs>
    </nav>
  )
}

export default FigureSideNav
