import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import config from './aws-exports'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-data-grid-pro'

import App from './App'
import { theme } from './theme/default'

import { UserProvider } from './hooks/useUser'
import 'normalize.css'
import './styles.scss'

import reportWebVitals from './reportWebVitals'

Amplify.configure(config)
LicenseInfo.setLicenseKey(
  'aec396d0e74cc45d079cea2d9823e71bT1JERVI6NDA4MjEsRVhQSVJZPTE2ODAzMDMzNDYwMDAsS0VZVkVSU0lPTj0x'
)
const container = document.getElementById('root')
const root = createRoot(container)
// console.log(theme)

root.render(
  <UserProvider>
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Router>
  </UserProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
