import React from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'

import { createCollection } from '../../graphql/mutations'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { alpha } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'

import { Footer } from '../../components/Footer'

const CollectionPrompt = ({ user }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const handleCreateCollection = async () => {
    try {
      const collectionInit = {
        email: user.attributes.email
      }
      await API.graphql(
        graphqlOperation(createCollection, { input: collectionInit })
      )
      navigate('/add-bulk')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <div className='o96-body o96-body--with-centered-footer '>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              mt: 5,
              px: 4,
              pt: 2,
              pb: 4,
              backgroundColor: theme =>
                alpha(theme.palette.background.default, 0.9),
              border: 1,
              borderRadius: 2,
              borderColor: theme => theme.palette.divider,
              maxWidth: 550
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography
                  variant='h4'
                  align='center'
                  sx={{
                    mb: 1
                  }}
                >
                  Are you a collector?
                </Typography>
              </Grid>

              <Typography
                variant='body2'
                align='center'
                sx={{
                  px: 3,
                  mb: 3
                }}
              >
                {user === 'unauthenticated'
                  ? 'Create an account to check out our collection tool where you can keep track of your own vintage Kenner collection.'
                  : 'Check out our collection management tool where you can keep track of your own vintage Kenner collection.'}
              </Typography>

              <Grid item xs={12}>
                {user === 'unauthenticated' ? (
                  <Button
                    fullWidth
                    type='submit'
                    size='large'
                    variant='contained'
                    component={RouterLink}
                    to={{
                      pathname: '/create-account'
                    }}
                    state={{ from: location.pathname }}
                  >
                    Create Account
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    type='submit'
                    size='large'
                    variant='contained'
                    onClick={handleCreateCollection}
                  >
                    Create Collection
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mt: 2
                }}
              >
                {user === 'unauthenticated' && (
                  <Typography variant='body2' align='center'>
                    Already have an account?{' '}
                    <Link
                      component={RouterLink}
                      to={{ pathname: '/sign-in' }}
                      state={{ from: location.pathname }}
                    >
                      Sign in
                    </Link>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <Footer theme={`centered`} />
    </>
  )
}

export default CollectionPrompt
