import React, { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { deleteAccessory } from '../../graphql/mutations'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import { Loader } from '../../components/Loader'
import useMediaQuery from '@mui/material/useMediaQuery'

const CollectionDeleteAccessoryModal = ({
  rowParamsAccessory,
  open,
  onClose,
  setFetchLatestCollection,
  setStatus
}) => {
  const [wasSubmitted, setWasSubmitted] = useState(false)
  const [showDeleter, setShowDeleter] = useState(false)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    setWasSubmitted(false)
  }, [wasSubmitted])

  const handleDeleteAccessory = async () => {
    setShowDeleter(true)
    try {
      API.graphql(
        graphqlOperation(deleteAccessory, {
          input: { id: rowParamsAccessory.id }
        })
      )
      await setFetchLatestCollection(x => !x)
      setStatus('fetching')
      await handleCloseDeleteAccessory()
    } catch (e) {
      console.log(e)
    }
  }

  const handleCloseDeleteAccessory = () => {
    setShowDeleter(false)
    onClose()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseDeleteAccessory}
        aria-labelledby='delete-accessory-modal-title'
        aria-describedby='delete-accessory-modal-description'
      >
        <Box
          className={`o96-modal__container ${
            isMobile ? 'o96-modal__container--mobile' : ''
          }`}
          sx={{ maxWidth: 750 }}
        >
          <Typography
            id='delete-accessory-modal-title'
            variant='h5'
            component='h2'
            sx={{ mb: 2 }}
          >
            Delete {rowParamsAccessory?.row.name}
          </Typography>

          <Typography
            id='delete-accessory-modal-description'
            variant='subtitle1'
            sx={{ mb: 5 }}
          >
            Deleting this {rowParamsAccessory?.row.name + ' '}
            will remove it from the collection. You can easily add items back at
            any time by clicking the{' '}
            {isMobile ? <span> "+" </span> : <span>"+ Add Items"</span>} button.
          </Typography>

          <div
            className={`o96-modal__button-well ${
              isMobile ? 'o96-modal__button-well--mobile' : ''
            }`}
          >
            <Button
              color='primary'
              aria-label='cancel'
              variant='outlined'
              size='large'
              fullWidth={isMobile ? true : false}
              onClick={handleCloseDeleteAccessory}
            >
              Cancel
            </Button>

            <Button
              onClick={handleDeleteAccessory}
              color='error'
              aria-label='save figure edits'
              variant='outlined'
              size='large'
              type='submit'
              fullWidth={isMobile ? true : false}
              sx={{ ml: isMobile ? 0 : 2, mb: isMobile ? 2 : 0 }}
              startIcon={<DeleteIcon />}
            >
              Delete Accessory
            </Button>
          </div>
          {showDeleter && <Loader loaderText='Deleting Accessory...' />}
        </Box>
      </Modal>
    </div>
  )
}

export default CollectionDeleteAccessoryModal
