import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import Textfield from '../../components/Forms/Textfield'

import useBodyClass from '../../hooks/useBodyClass'

const initialFormValues = { code: '' }
const formValidation = Yup.object().shape({
  code: Yup.string().required('Verification Code is Required')
})

const ConfirmAccountForm = ({ passedEmail }) => {
  useBodyClass('o96-bg--space')

  const navigate = useNavigate()
  const location = useLocation()

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await Auth.confirmSignUp(passedEmail, values.code)
      setSubmitting(false)
      navigate('/sign-in', { state: { from: location.state.from } })
    } catch (e) {
      console.log(e)
      setErrors({ code: e.message })
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, isValid }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Textfield name='code' type='code' label='Verification Code' />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type='submit'
                size='large'
                disabled={isSubmitting || !isValid}
                variant='contained'
              >
                Confirm Account
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default ConfirmAccountForm
