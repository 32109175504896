/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      collection
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        collection
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompleteFigure = /* GraphQL */ `
  query GetCompleteFigure($id: ID!) {
    getCompleteFigure(id: $id) {
      id
      keyName
      name
      accessories {
        keyName
        name
        id
        shortName
        linkPath
        ebayLink
      }
      accessoryVariants {
        keyName
        name
        id
      }
      ebayLink
      variantName
      variantType
      initialReleaseYear
      currentRelease
      linkPath
      hexColor
      textColor
      initialReleaseWave
      initialReleaseWaveLabel
      shortName
      inStories {
        items {
          id
          firstAppearedIn
          affiliation
          species
          createdAt
          updatedAt
          completeFigureInStoriesId
        }
        nextToken
      }
      likes {
        items {
          id
          email
          createdAt
          updatedAt
          completeFigureLikesId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompleteFigures = /* GraphQL */ `
  query ListCompleteFigures(
    $filter: ModelCompleteFigureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompleteFigures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keyName
        name
        accessories {
          keyName
          name
          id
          shortName
          linkPath
          ebayLink
        }
        accessoryVariants {
          keyName
          name
          id
        }
        ebayLink
        variantName
        variantType
        initialReleaseYear
        currentRelease
        linkPath
        hexColor
        textColor
        initialReleaseWave
        initialReleaseWaveLabel
        shortName
        inStories {
          nextToken
        }
        likes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStory = /* GraphQL */ `
  query GetStory($id: ID!) {
    getStory(id: $id) {
      id
      firstAppearedIn
      affiliation
      species
      createdAt
      updatedAt
      completeFigureInStoriesId
    }
  }
`;
export const listStories = /* GraphQL */ `
  query ListStories(
    $filter: ModelStoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstAppearedIn
        affiliation
        species
        createdAt
        updatedAt
        completeFigureInStoriesId
      }
      nextToken
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      email
      createdAt
      updatedAt
      completeFigureLikesId
      owner
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        createdAt
        updatedAt
        completeFigureLikesId
        owner
      }
      nextToken
    }
  }
`;
export const getCollection = /* GraphQL */ `
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      figures {
        items {
          id
          keyName
          maker
          condition
          note
          createdAt
          updatedAt
          collectionFiguresId
          owner
        }
        nextToken
      }
      accessories {
        items {
          id
          keyName
          name
          maker
          condition
          note
          figureAccessoriesId
          createdAt
          updatedAt
          collectionAccessoriesId
          owner
        }
        nextToken
      }
      email
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCollections = /* GraphQL */ `
  query ListCollections(
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        figures {
          nextToken
        }
        accessories {
          nextToken
        }
        email
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFigure = /* GraphQL */ `
  query GetFigure($id: ID!) {
    getFigure(id: $id) {
      id
      keyName
      maker
      condition
      note
      createdAt
      accessories {
        items {
          id
          keyName
          name
          maker
          condition
          note
          figureAccessoriesId
          createdAt
          updatedAt
          collectionAccessoriesId
          owner
        }
        nextToken
      }
      updatedAt
      collectionFiguresId
      owner
    }
  }
`;
export const listFigures = /* GraphQL */ `
  query ListFigures(
    $filter: ModelFigureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFigures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keyName
        maker
        condition
        note
        createdAt
        accessories {
          nextToken
        }
        updatedAt
        collectionFiguresId
        owner
      }
      nextToken
    }
  }
`;
export const figureKeyNamesByDate = /* GraphQL */ `
  query FigureKeyNamesByDate(
    $keyName: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFigureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    figureKeyNamesByDate(
      keyName: $keyName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keyName
        maker
        condition
        note
        createdAt
        accessories {
          nextToken
        }
        updatedAt
        collectionFiguresId
        owner
      }
      nextToken
    }
  }
`;
export const getAccessory = /* GraphQL */ `
  query GetAccessory($id: ID!) {
    getAccessory(id: $id) {
      id
      keyName
      name
      maker
      condition
      note
      figureAccessoriesId
      createdAt
      updatedAt
      collectionAccessoriesId
      owner
    }
  }
`;
export const listAccessories = /* GraphQL */ `
  query ListAccessories(
    $filter: ModelAccessoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccessories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keyName
        name
        maker
        condition
        note
        figureAccessoriesId
        createdAt
        updatedAt
        collectionAccessoriesId
        owner
      }
      nextToken
    }
  }
`;
