// Framework Imports
import React, { useState, useCallback } from 'react'

// Framework Component Imports
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridActionsCellItem
} from '@mui/x-data-grid-pro'

// Custom Component Imports
import { Loader } from '../../components/Loader'
import { CollectionGridColumnAvatar } from '../../components/CollectionGridColumnAvatar'
import { CollectionItemDetailRow } from '../../components/CollectionItemDetailRow'
import { CollectionDetailToggle } from '../../components/CollectionDetailToggle'
import { CollectionEditFigureModal } from '../../components/CollectionEditFigureModal'
import { CollectionDeleteFigureModal } from '../../components/CollectionDeleteFigureModal'

// Data Fetch Hook Imports
import { useGetCollection } from '../../hooks/useGetCollection'
import { useGetFigures } from '../../hooks/useGetFigures'

const CollectionGridFigures = () => {
  const [fetchLatestCollection, setFetchLatestCollection] = useState(true)
  const { collection, status, setStatus } = useGetCollection(
    fetchLatestCollection
  )
  const { completeFigures } = useGetFigures()

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([])
  const handleDetailPanelExpandedRowIdsChange = useCallback(newIds => {
    setDetailPanelExpandedRowIds(newIds)
  }, [])

  const [rowParamsFigure, setRowParamsFigure] = useState()
  const [openEditFigure, setOpenEditFigure] = useState(false)
  const [openDeleteFigure, setOpenDeleteFigure] = useState(false)

  const collectionFigz = collection.figures?.items
  const collectionAndDetails = collectionFigz?.map(itm => ({
    ...completeFigures?.find(item => item.keyName === itm.keyName && item),
    ...itm
  }))

  // Modal Handlers
  const handleOpenEditFigure = params => {
    setRowParamsFigure(params)
    setOpenEditFigure(true)
  }

  const handleOpenDeleteFigure = params => {
    setRowParamsFigure(params)
    setOpenDeleteFigure(true)
  }

  const handleCloseEditFigure = () => {
    setOpenEditFigure(false)
  }

  const handleCloseDeleteFigure = () => {
    setOpenDeleteFigure(false)
  }

  // Properties for Modals
  const sharedProps = {
    collection: collection,
    setStatus: setStatus,
    setFetchLatestCollection: setFetchLatestCollection,
    rowParamsFigure: rowParamsFigure
  }

  const deleteModalProps = {
    open: openDeleteFigure,
    onClose: handleCloseDeleteFigure
  }

  const editModalProps = {
    open: openEditFigure,
    onClose: handleCloseEditFigure
  }

  // Columns Definition
  const columns = [
    {
      field: 'avatar',
      headerName: '',
      align: 'center',
      width: 65,
      renderCell: params => {
        return (
          <CollectionGridColumnAvatar
            maker={params.row.maker}
            hexColor={params.row.hexColor}
            name={params.row.name}
            directoryPath={'/img/collection/portraits/'}
            linkPath={params.row.linkPath}
          />
        )
      }
    },
    {
      field: 'name',
      headerName: 'Figure',
      width: 290,
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <span>{params.row.name}</span>
            <small>
              <i>{params.row.variantName}</i>
            </small>
          </Box>
        )
      }
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      headerName: 'Accessories',
      type: 'string',
      width: 127,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <CollectionDetailToggle
          id={params.id}
          value={params.value}
          allAccessories={params.row.accessories}
          collection={collection}
        />
      )
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 436
    },
    {
      field: 'condition',
      headerName: 'Condition',
      type: 'number',
      width: 138,
      renderCell: params => {
        if (params.row.condition === 0) {
          return 'N/A'
        } else if (params.row.condition > 0) {
          return params.row.condition
        }
      }
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: params => [
        <GridActionsCellItem
          onClick={() => handleOpenEditFigure(params)}
          icon={<EditIcon />}
          label='Edit'
        />,
        <GridActionsCellItem
          onClick={() => handleOpenDeleteFigure(params)}
          icon={<DeleteIcon />}
          label='Delete'
        />
      ]
    }
  ]

  return (
    <>
      {status === 'fetching' && (
        <Loader loaderText='Loading Figures with Accessories...' />
      )}

      {status === 'fetched' && (
        <>
          <DataGridPro
            rows={collectionAndDetails}
            columns={columns}
            density='comfortable'
            pageSize={50}
            rowsPerPageOptions={[50]}
            rowThreshold={0}
            pagination
            getDetailPanelContent={({ row }) => (
              <CollectionItemDetailRow
                row={row}
                collection={collection}
                hexColor={row.hexColor}
              />
            )}
            getDetailPanelHeight={({ row }) => {
              if (row?.accessories?.length === 1) {
                return 52
              } else if (row?.accessories?.length === 2) {
                return 104
              }
            }}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={
              handleDetailPanelExpandedRowIdsChange
            }
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }]
              },
              pinnedColumns: { right: ['actions'] }
            }}
          />
          <CollectionEditFigureModal {...editModalProps} {...sharedProps} />
          <CollectionDeleteFigureModal {...deleteModalProps} {...sharedProps} />
        </>
      )}
    </>
  )
}

export default CollectionGridFigures
