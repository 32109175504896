import React, { useState, useEffect, useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { updateAccessory } from '../../graphql/mutations'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { Loader } from '../../components/Loader'
import useMediaQuery from '@mui/material/useMediaQuery'

const CollectionEditAccessoryModal = ({
  rowParamsAccessory,
  open,
  onClose,
  setFetchLatestCollection,
  setStatus
}) => {
  const [showSaver, setShowSaver] = useState(false)
  const [accessoryMaker, setAccessoryMaker] = useState('')
  const [accessoryNote, setAccessoryNote] = useState('')
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    setAccessoryMaker(rowParamsAccessory?.row.maker)
    setAccessoryNote(rowParamsAccessory?.row.note)
  }, [rowParamsAccessory])

  useEffect(() => {
    setShowSaver(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initAccessory = {
    maker: rowParamsAccessory?.row.maker,
    note: rowParamsAccessory?.row.note
  }

  const handleAccessoryMaker = e => {
    if (accessoryMaker === e.target.value) {
      setAccessoryMaker('N/A')
    } else {
      setAccessoryMaker(e.target.value)
    }
  }

  const handleAccessoryNote = e => {
    if (e.target.value === '') {
      setAccessoryNote()
    }
    setAccessoryNote(e.target.value)
  }

  const initCurrentCheck = (initProp, currentProp) => {
    if (currentProp && initProp !== currentProp) {
      return true
    } else if (currentProp === 0 && currentProp !== initProp) {
      return true
    }
  }

  const anyDiffs = useCallback(() => {
    if (initAccessory.maker !== accessoryMaker) {
      return true
    } else if (initAccessory.note !== accessoryNote) {
      return true
    } else {
      return false
    }
  }, [accessoryMaker, accessoryNote, initAccessory.maker, initAccessory.note])

  const accessoryUpdate = async () => {
    try {
      const accessoryInput = {
        ...(initCurrentCheck(initAccessory.maker, accessoryMaker) && {
          maker: accessoryMaker
        }: {}),
        ...(initCurrentCheck(initAccessory.note, accessoryNote) && {
          note: accessoryNote
        }: {}),
        ...(!accessoryNote && {
          note: null
        })
      }

      await API.graphql(
        graphqlOperation(updateAccessory, {
          input: { id: rowParamsAccessory.id, ...accessoryInput }
        })
      )
      await setFetchLatestCollection(x => !x)
      await setShowSaver(false)
      setStatus('fetching')
      await onClose()
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (anyDiffs()) {
      setShowSaver(true)
      accessoryUpdate()
    } else {
      onClose()
    }
  }

  const handleCloseEdit = () => {
    onClose()
    setShowSaver(false)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseEdit}
        aria-labelledby='edit-accessory-modal-title'
      >
        <Box
          className={`o96-modal__container ${
            isMobile ? 'o96-modal__container--mobile' : ''
          }`}
        >
          <Typography
            id='edit-accessory-modal-title'
            variant='h5'
            component='h2'
            sx={{ mb: 5 }}
          >
            {rowParamsAccessory?.row.name}
          </Typography>
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3} alignItems='center' sx={{ mb: 4 }}>
              <Grid item xs={2} md={2} textAlign={{ xs: 'left', md: 'right' }}>
                <Typography variant='boldLabel'>Type:</Typography>
              </Grid>
              <Grid item xs={10} md={4} textAlign={{ xs: 'right', md: 'left' }}>
                <ToggleButtonGroup
                  value={accessoryMaker}
                  color='primary'
                  size='large'
                  exclusive
                  onChange={handleAccessoryMaker}
                  aria-label='Maker of Accessory'
                >
                  <ToggleButton
                    value='vintageKenner'
                    aria-label='Vintage Kenner'
                  >
                    Vintage Kenner
                  </ToggleButton>
                  <ToggleButton value='custom' aria-label='Custom'>
                    Custom
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                textAlign={{ xs: 'left', md: 'right' }}
                justifyContent='flex-start'
              >
                <Typography variant='boldLabel'>Note:</Typography>
              </Grid>
              <Grid item xs={10} md={4}>
                <TextField
                  id='outlined-multiline-static'
                  value={accessoryNote ? accessoryNote : ''}
                  onChange={handleAccessoryNote}
                  label='Note'
                  multiline
                  size='large'
                  rows={2}
                  fullWidth
                  inputProps={{ maxLength: 150 }}
                />
              </Grid>
            </Grid>

            <Box
              className={`o96-modal__button-well ${
                isMobile ? 'o96-modal__button-well--mobile' : ''
              }`}
            >
              <Button
                color='primary'
                aria-label='cancel'
                variant='outlined'
                size='large'
                onClick={handleCloseEdit}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                aria-label='save accessory edits'
                variant='contained'
                size='large'
                type='submit'
                sx={{ ml: isMobile ? 0 : 2, mb: isMobile ? 2 : 0 }}
              >
                Save
              </Button>
            </Box>
          </form>
          {showSaver && <Loader loaderText='Saving Updates...' />}
        </Box>
      </Modal>
    </div>
  )
}

export default CollectionEditAccessoryModal
