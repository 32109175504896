import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { CollectionAddBulkFigure } from '../CollectionAddBulkFigure'
import { CollectionAddBulkAccessory } from '../CollectionAddBulkAccessory'

const CollectionAddBulkCard = ({
  collection,
  keyName,
  textColor,
  hexColor,
  linkPath,
  name,
  variantType,
  variantName,
  accessories,
  accessoryVariants,
  wasSubmitted,
  setTotalFiguresAdded,
  totalFiguresAdded,
  setTotalAccessoriesAdded,
  totalAccessoriesAdded,
  setTotalAccessoriesWithFigsAdded,
  totalAccessoriesWithFigsAdded
}) => {
  const [figuresToAdd, setFiguresToAdd] = useState([])
  const [figuresHaveAdded, setFiguresHaveAdded] = useState(false)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{
        m: 2,
        width: isMobile ? '100%' : 'auto'
      }}
    >
      <Card variant='outlined'>
        <div
          style={{
            background: hexColor,
            display: 'flex',
            width: '100%',
            paddingRight:
              isMobile && accessories
                ? '.5rem'
                : !isMobile && accessories
                ? '2rem'
                : '0',
            justifyContent: 'space-evenly'
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img
              src={`/img/collection/figures/${linkPath}.png`}
              alt={keyName}
              style={{
                width: isMobile ? '65px' : '120px',
                margin: '.75rem 1rem .5rem'
              }}
            />
            <CollectionAddBulkFigure
              keyName={keyName}
              textColor={textColor}
              wasSubmitted={wasSubmitted}
              collection={collection}
              setFiguresToAdd={setFiguresToAdd}
              figuresToAdd={figuresToAdd}
              setFiguresHaveAdded={setFiguresHaveAdded}
              setTotalFiguresAdded={setTotalFiguresAdded}
              totalFiguresAdded={totalFiguresAdded}
              totalAccessoriesAdded={totalAccessoriesAdded}
              noAccessories={!accessories && true}
            />
          </div>
          {accessories &&
            accessories.map((a, i) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  margin: '0 .25rem 0'
                }}
                key={i}
              >
                {a.shortName === 'cape' && (
                  <img
                    src={`/img/collection/accessories/${a.linkPath}.png`}
                    alt={a.keyName}
                    style={{
                      height: isMobile ? '90px' : '210px',
                      margin: '0 .5rem 1.25rem'
                    }}
                  />
                )}

                {a.shortName === 'blaster' && (
                  <img
                    src={`/img/collection/accessories/${a.linkPath}.png`}
                    alt={a.keyName}
                    style={{
                      height: isMobile ? '70px' : '132px',
                      margin: '0 .5rem 1.25rem'
                    }}
                  />
                )}

                {a.shortName === 'lightsaber' && (
                  <img
                    src={`/img/collection/accessories/${a.linkPath}.png`}
                    alt={a.keyName}
                    style={{
                      height: isMobile ? '90px' : '140px',
                      margin: '0 .5rem 1.25rem'
                    }}
                  />
                )}

                {a.shortName === 'staff' && (
                  <img
                    src={`/img/collection/accessories/${a.linkPath}.png`}
                    alt={a.keyName}
                    style={{
                      height: isMobile ? '75px' : '130px',
                      margin: '0 .5rem 1.25rem'
                    }}
                  />
                )}
                <CollectionAddBulkAccessory
                  keyName={a.keyName}
                  figureKeyName={keyName}
                  name={a.name}
                  collection={collection}
                  textColor={textColor}
                  figuresToAdd={figuresToAdd}
                  setFiguresToAdd={setFiguresToAdd}
                  figuresHaveAdded={figuresHaveAdded}
                  setFiguresHaveAdded={setFiguresHaveAdded}
                  setTotalAccessoriesAdded={setTotalAccessoriesAdded}
                  totalAccessoriesAdded={totalAccessoriesAdded}
                  setTotalAccessoriesWithFigsAdded={
                    setTotalAccessoriesWithFigsAdded
                  }
                  totalAccessoriesWithFigsAdded={totalAccessoriesWithFigsAdded}
                  wasSubmitted={wasSubmitted}
                />
              </div>
            ))}
        </div>

        <CardContent>
          <Typography sx={{ fontSize: 14 }} color='text.secondary'>
            {name}
          </Typography>
          {/* <Typography sx={{ fontSize: 12 }} color='text.secondary'>
            <i>{variantName && variantName} </i>
          </Typography> */}
        </CardContent>
      </Card>
    </Box>
  )
}

export default CollectionAddBulkCard
