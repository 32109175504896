import React from 'react'
import { Auth } from 'aws-amplify'

import { Link as RouterLink, Navigate } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { alpha } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import useMediaQuery from '@mui/material/useMediaQuery'

import Textfield from '../../components/Forms/Textfield'

import useBodyClass from '../../hooks/useBodyClass'
import { useUser } from '../../hooks/useUser'

import { Footer } from '../../components/Footer'

const initialFormValues = { email: '', password: '' }
const formValidation = Yup.object().shape({
  email: Yup.string().required('Email is Required'),
  password: Yup.string().required('Password is Required')
})

const SignInPage = () => {
  useBodyClass('o96-bg--space')
  const { login } = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { user } = useUser()

  if (user?.username) {
    return <Navigate to='/' replace />
  }

  const mostRecentFrom = location.state?.from

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await login(values.email, values.password)
      setSubmitting(false)
      navigate(
        location.state?.from &&
          location.state.from !== '/create-account' &&
          location.state.from !== '/confirm-account' &&
          location.state.from !== '/forgot-password' &&
          location.state.from !== '/sign-in'
          ? location.state.from
          : '/'
      )
    } catch (err) {
      if (err.code === 'UserNotFoundException') {
        setErrors({ email: 'Sorry, the email could not be found' })
      } else if (err.code === 'NotAuthorizedException') {
        setErrors({
          password: 'Sorry, the password is incorrect, please try again'
        })
      } else if (err.code === 'UserNotConfirmedException') {
        await Auth.resendSignUp(values.email)
        navigate('/confirm-account', {
          state: { userEmail: values.email, from: location.state.from }
        })
      }
      setSubmitting(false)
    }
  }

  return (
    <>
      <div
        className={`o96-body o96-body--with-centered-footer ${
          isMobile ? 'o96-body--mobile' : ''
        }`}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              mt: 5,
              px: 4,
              pt: 2,
              pb: 4,
              backgroundColor: theme =>
                alpha(theme.palette.background.default, 0.9),
              border: 1,
              borderRadius: 2,
              borderColor: theme => theme.palette.divider,
              maxWidth: 550
            }}
          >
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              align='center'
              sx={{
                mb: 3
              }}
            >
              Sign In
            </Typography>
            <Formik
              initialValues={initialFormValues}
              validationSchema={formValidation}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, isValid }) => (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Textfield name='email' type='email' label='Email' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Textfield
                        name='password'
                        type='password'
                        label='Password'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        type='submit'
                        size='large'
                        disabled={isSubmitting || !isValid}
                        variant='contained'
                        sx={{ mb: { xs: 2, md: 0 } }}
                      >
                        Sign In
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} sx={{ pt: 0 }}>
                <Typography
                  variant='body2'
                  align={isMobile ? 'center' : 'left'}
                  sx={{ mt: 2 }}
                >
                  <Link
                    component={RouterLink}
                    to='/forgot-password'
                    state={{ from: mostRecentFrom }}
                  >
                    Forgot My Password
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant='body2'
                  align={isMobile ? 'center' : 'right'}
                  sx={{ mt: 2 }}
                >
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: '/create-account'
                    }}
                    state={{ from: mostRecentFrom }}
                  >
                    Create Account
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <Footer theme={`centered`} />
    </>
  )
}

export default SignInPage
