import React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const PillButton = ({
  buttonPath,
  buttonText,
  currentFigure,
  buttonTarget = '_blank'
}) => {
  const StyledPill = styled(Button)({
    background:
      'linear-gradient(-45deg,#cfccbf 0%,#a0a698 2.83%,#8a967d 4.25%,#e1d9ce 5.03%,#e1d9ce 6.86%,#797669 7.26%,#535245 7.57%,#535245 8.01%,#838c7e 9.17%,#97a496 10.29%,#ddd6cc 13.89%,#d5cfc6 25.37%,#aeaca8 28.18%,#524f50 28.67%,#a09f8f 31.07%,#a2a587 33.28%,#e1dad1 36.04%,#bdbdb2 37.73%,#b6b5ac 37.96%,#6e7a66 39.1%,#84867a 40.26%,#d8d6c9 42.23%,#cccabe 45.72%,#7c7b6c 48.5%,#7c7b6c 49.21%,#cccabe 51.97%,#cccabe 53.5%,#899e90 56.15%,#cccabe 56.84%,#857f65 58.36%,#6b6651 59.94%,#cec5c0 62.95%,#abafaa 64.71%,#d0cfc3 68.25%,#a39888 76.88%,#d9d1c8 77.62%,#b1b4a1 83.21%,#838379 83.87%,#b59472 85.66%,#4b4543 89.48%,#454239 90.77%,#ccc5ba 92.65%,#99a199 97.63%,#d4cec3 100%)',
    borderRadius: '48px',
    color: 'white',
    padding: 0,
    '.o96-pill-button__gradient-overlay': {
      width: '100%',
      height: '100%',
      borderRadius: '48px',
      transition: `background-color 0.25s ease`
    },
    '&:hover .o96-pill-button__gradient-overlay': {
      background: currentFigure?.hexColor,
      borderColor: currentFigure?.hexColor,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: currentFigure?.hexColor,
      borderColor: currentFigure?.hexColor
    },
    '&:focus': {
      boxShadow: '0 0 0 1px' + currentFigure?.hexColor
    },
    '& .o96-pill-button__swatch': {
      backgroundColor: currentFigure?.hexColor,
      borderColor: '#0062cc',
      boxShadow: 'none',
      color: currentFigure?.textColor,
      textTransform: 'none',
      padding: '0.25rem 1.25rem',
      textAlign: 'center',
      fontWeight: 400,
      fontSize: '1rem',
      margin: '0.55rem 1.25rem'
    }
  })

  return (
    <StyledPill
      href={buttonPath}
      target={buttonTarget}
      rel={buttonTarget === '_blank' ? 'noreferrer' : ''}
    >
      <div className='o96-pill-button__gradient-overlay'>
        <div className='o96-pill-button__swatch'>{buttonText}</div>
      </div>
    </StyledPill>
  )
}
export default PillButton
