import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'

import logo from '../../img/original96-yellow.svg'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DrawerMenu } from '../../components/DrawerMenu'

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [value, setValue] = useState(false)
  const { logout, user } = useUser()
  const location = useLocation()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))

  useEffect(() => {
    let path = location.pathname
    if (isMobile) setValue(false)
    if (path === '/') setValue(0)
    else if (path.startsWith('/figures')) setValue(1)
    else if (path.startsWith('/collection')) setValue(2)
    else if (path.startsWith('/charts')) setValue(3)
    else if (path.startsWith('/about')) setValue(4)
    else if (path.startsWith('/create-account')) setValue(false)
    else if (path.startsWith('/sign-in')) setValue(false)
    else if (path.startsWith('/forgot-password')) setValue(false)
    else if (path.startsWith('/settings')) setValue(false)
    else if (path === '*') setValue(false)
  }, [location.pathname, isMobile, user])

  const handleMenu = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setAnchorEl(null)
    logout()
  }

  return (
    <header className={`o96-header ${isMobile ? 'o96-header--mobile' : ''}`}>
      <div className='o96-header__logo'>
        <Link to='/'>
          <img src={logo} className='o96-header__logo-img' alt='ORIGINAL96' />
        </Link>
        {!isMobile && <div className='o96-header__logo-divider'></div>}
      </div>
      {isMobile ? (
        <DrawerMenu className='drawer-component' />
      ) : (
        <div className='o96-header__nav'>
          <Tabs value={value} aria-label='Top Level Navigation'>
            <Tab component={NavLink} to='/' label='Home' />
            <Tab component={NavLink} to='/figures' label='Figures' />
            <Tab component={NavLink} to='/collection' label='Collection' />
            <Tab component={NavLink} to='/charts' label='Charts' />
          </Tabs>

          <div>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
            >
              <AccountCircle />
            </IconButton>
            {user === 'unauthenticated' ? (
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  component={Link}
                  to={{
                    pathname: '/sign-in'
                  }}
                  state={{ from: location.pathname }}
                  onClick={handleClose}
                >
                  Sign In
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={{
                    pathname: '/create-account'
                  }}
                  state={{ from: location.pathname }}
                  onClick={handleClose}
                >
                  Create Account
                </MenuItem>
              </Menu>
            ) : (
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  component={Link}
                  to={{
                    pathname: '/settings'
                  }}
                  state={{ from: location.pathname }}
                  onClick={handleClose}
                >
                  {' '}
                  Settings
                </MenuItem>
                <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
              </Menu>
            )}
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
