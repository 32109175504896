import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'

const Loader = ({ loaderText }) => {
  return (
    <Container
      maxWidth='false'
      sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: '1201',
        borderRadius: '1rem'
      }}
    >
      <CircularProgress sx={{ mb: 2 }} />
      {loaderText}
    </Container>
  )
}

export default Loader
