import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
      ','
    ),
    fontWeightThin: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    boldLabel: {
      fontWeight: 500
    },
    publicSans: {
      fontFamily: [
        'Public Sans',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
      ].join(','),
      fontWeightMedium: 500
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#F5CF47'
    },
    secondary: {
      main: '#FFF'
    },
    black: {
      main: '#000',
      light: '#333',
      dark: '#000',
      contrastText: '#fff'
    },
    tertiary: {
      main: '#444',
      light: '#777',
      dark: '#000',
      contrastText: '#fff'
    },
    white: {
      main: '#fff',
      light: '#999',
      dark: '#ddd',
      contrastText: '#000'
    },
    dark: {
      main: '#333'
    },
    light: {
      main: '#EFFFFA'
    },
    yellow: {
      main: '#F5CF47'
    },
    teal: {
      main: '#00B1AC'
    },
    olive: {
      main: '#719500'
    },
    cyan: {
      main: '#00A0DF'
    },
    red: {
      main: '#E70033'
    },
    darkGreen: {
      main: '#007836'
    },
    darkOrange: {
      main: '#D6492A'
    },
    darkBlue: {
      main: '#0039A6'
    }
  }
})
