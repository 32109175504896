import { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { fetchWithAuthMode } from '../utils'
import { listLikes } from '../graphql/queries'

export const useGetLikes = likesRefetch => {
  const [likesStatus, setLikesStatus] = useState('idle')
  const [allFigureLikes, setAllFigureLikes] = useState([])

  useEffect(() => {
    const fetchLikes = async mode => {
      setLikesStatus('fetching')
      const likesData = await API.graphql({
        query: listLikes,
        authMode: mode
      })
      const allLikesArray = likesData.data.listLikes.items
      setAllFigureLikes(allLikesArray)
      setLikesStatus('fetched')
    }

    fetchWithAuthMode(fetchLikes)
  }, [likesRefetch])

  return { likesStatus, allFigureLikes }
}
