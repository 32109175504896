import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'

const Footer = ({ theme = 'on-dark' }) => {
  return (
    <footer className={`o96-footer o96-footer--${theme}`}>
      <div>
        &copy;{new Date().getFullYear()} Original96 LLC. All Rights Reserved.
      </div>
      <div style={{ textAlign: 'right' }}>
        <Link
          component={RouterLink}
          to={{
            pathname: '/terms-and-conditions'
          }}
          color={theme === 'on-light' ? '#333' : '#ccc'}
        >
          Terms and Conditions
        </Link>
        {` | `}
        <Link
          component={RouterLink}
          to={{
            pathname: '/privacy-policy'
          }}
          color={theme === 'on-light' ? '#333' : '#ccc'}
        >
          Privacy Policy
        </Link>
        {` | `}
        <Link
          component={RouterLink}
          to={{
            pathname: '/disclaimer'
          }}
          color={theme === 'on-light' ? '#333' : '#ccc'}
        >
          Disclaimer
        </Link>
      </div>
    </footer>
  )
}

export default Footer
