import React, { useState, useEffect } from 'react'
import { CollectionEditFigure } from '../../components/CollectionEditFigure'
import { CollectionEditAccessory } from '../../components/CollectionEditAccessory'
import { Loader } from '../../components/Loader'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'

const CollectionEditFigureModal = ({
  rowParamsFigure,
  open,
  onClose,
  collection,
  setStatus,
  setFetchLatestCollection
}) => {
  const [wasSubmitted, setWasSubmitted] = useState(false)
  const [figureHasEdited, setFigureHasEdited] = useState(false)
  const [showSaver, setShowSaver] = useState(false)
  const [allAccessories, setAllAccessories] = useState([])
  const [ownedAccessories, setOwnedAccessories] = useState([])
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    setWasSubmitted(false)
  }, [wasSubmitted])

  useEffect(() => {
    if (rowParamsFigure?.row.accessories === null) {
      console.log('No Accessories')
    } else if (rowParamsFigure?.row.accessories.length > 0) {
      setAllAccessories(rowParamsFigure?.row.accessories)
    }
  }, [rowParamsFigure?.row.accessories])

  useEffect(() => {
    const currentlyOwned = collection?.accessories.items.filter(
      x => x.figureAccessoriesId === rowParamsFigure?.id
    )
    setOwnedAccessories(currentlyOwned)
  }, [collection?.accessories.items, rowParamsFigure?.id])

  const handleSubmit = async event => {
    event.preventDefault()
    setWasSubmitted(true)
    setShowSaver(true)
  }

  const handleCloseEdit = () => {
    setShowSaver(false)
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseEdit}
      aria-labelledby='edit-figure-modal-title'
    >
      <form noValidate onSubmit={handleSubmit}>
        <Box
          className={`o96-modal__container ${
            isMobile ? 'o96-modal__container--mobile' : ''
          }`}
        >
          <Typography
            id='edit-figure-modal-title'
            variant='h5'
            component='h2'
            sx={{ mb: 5 }}
          >
            {rowParamsFigure?.row.name}
          </Typography>
          <CollectionEditFigure
            rowParamsFigure={rowParamsFigure}
            wasSubmitted={wasSubmitted}
            setFigureHasEdited={setFigureHasEdited}
          />
          <CollectionEditAccessory
            rowParamsFigure={rowParamsFigure}
            collection={collection}
            wasSubmitted={wasSubmitted}
            figureHasEdited={figureHasEdited}
            setFigureHasEdited={setFigureHasEdited}
            setFetchLatestCollection={setFetchLatestCollection}
            handleCloseEdit={handleCloseEdit}
            allAccessories={allAccessories}
            ownedAccessories={ownedAccessories}
            setStatus={setStatus}
          />
          {!isMobile && (
            <Box className='o96-modal__button-well'>
              <Button
                color='primary'
                aria-label='cancel'
                variant='outlined'
                size='large'
                onClick={handleCloseEdit}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                aria-label='save figure edits'
                variant='contained'
                size='large'
                type='submit'
                sx={{ ml: 2 }}
              >
                Save
              </Button>
            </Box>
          )}
          {showSaver && <Loader loaderText='Saving Updates...' />}
        </Box>
        {isMobile && (
          <Box className='o96-modal__button-well o96-modal__button-well--mobile'>
            <Button
              color='primary'
              aria-label='cancel'
              variant='outlined'
              size='large'
              onClick={handleCloseEdit}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              aria-label='save figure edits'
              variant='contained'
              size='large'
              type='submit'
              sx={{ ml: 2 }}
            >
              Save
            </Button>
          </Box>
        )}
      </form>
    </Modal>
  )
}

export default CollectionEditFigureModal
