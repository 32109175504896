import React, { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createAccessory } from '../../graphql/mutations'
import { figureKeyNamesByDate } from '../../graphql/queries'

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

const CollectionAddBulkAccessory = ({
  name,
  keyName,
  textColor,
  collection,
  figuresToAdd,
  setFiguresToAdd,
  figuresHaveAdded,
  setFiguresHaveAdded,
  figureKeyName,
  wasSubmitted,
  setTotalAccessoriesAdded,
  totalAccessoriesAdded,
  setTotalAccessoriesWithFigsAdded,
  totalAccessoriesWithFigsAdded
}) => {
  const collectionId = collection?.id
  const [accessoriesToAdd, setAccessoriesToAdd] = useState([])

  useEffect(() => {
    setAccessoriesToAdd([])
    setFiguresToAdd([])
    setFiguresHaveAdded(false)
  }, [figuresHaveAdded, setFiguresHaveAdded, setFiguresToAdd])

  const addAccessoriesWithFigureIds = async () => {
    try {
      const addedFigureData = await API.graphql(
        graphqlOperation(figureKeyNamesByDate, {
          limit: figuresToAdd.length,
          keyName: figureKeyName,
          sortDirection: 'DESC'
        })
      )
      setFiguresToAdd([])
      const addedFigures = await addedFigureData.data.figureKeyNamesByDate.items

      await Promise.all(
        accessoriesToAdd.map(() => {
          if (addedFigures.length > 0) {
            const fig = addedFigures.find(x => x.id)
            const accessoryInputWithFig = {
              keyName: keyName,
              name: name,
              collectionAccessoriesId: collectionId,
              figureAccessoriesId: fig.id
            }
            API.graphql(
              graphqlOperation(createAccessory, {
                input: accessoryInputWithFig
              }),
              totalAccessoriesWithFigsAdded.push(keyName)
            )
            addedFigures.shift()
          } else {
            const accessoryInput = {
              keyName: keyName,
              name: name,
              collectionAccessoriesId: collectionId
            }
            API.graphql(
              graphqlOperation(createAccessory, { input: accessoryInput }),
              totalAccessoriesAdded.push(keyName)
            )
          }
          return console.log('added figures with accessories')
        })
      )
      setTotalAccessoriesAdded(
        totalAccessoriesAdded.concat(totalAccessoriesWithFigsAdded)
      )
      await setFiguresHaveAdded(false)
      await setAccessoriesToAdd([])
    } catch (e) {
      console.log(e)
    }
  }

  const addAccessories = async () => {
    try {
      await Promise.all(
        accessoriesToAdd.map(() =>
          API.graphql(
            graphqlOperation(createAccessory, {
              input: {
                keyName: keyName,
                name: name,
                collectionAccessoriesId: collectionId
              }
            }),
            totalAccessoriesAdded.push(keyName)
          )
        )
      )
      totalAccessoriesAdded.concat(totalAccessoriesWithFigsAdded)
      await setAccessoriesToAdd([])
    } catch (e) {
      console.log(e)
    }
  }

  const addItem = async () => {
    accessoriesToAdd.length < 50 &&
      setAccessoriesToAdd([...accessoriesToAdd, keyName])
  }

  const deleteItem = async () => {
    accessoriesToAdd.pop()
    setAccessoriesToAdd([...accessoriesToAdd])
  }

  if (figuresHaveAdded && accessoriesToAdd.length > 0) {
    addAccessoriesWithFigureIds()
  }

  if (
    wasSubmitted &&
    figuresToAdd.length === 0 &&
    accessoriesToAdd.length > 0
  ) {
    addAccessories()
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: '1.5rem',
        background:
          textColor === 'black'
            ? 'rgba(255, 255, 255, .2)'
            : 'rgba(0, 0, 0, .1)',
        borderRadius: '.5rem'
      }}
    >
      <div
        style={{
          color: textColor === 'black' ? 'black' : 'white',
          fontWeight: '500',
          padding: '.25rem'
        }}
      >
        {accessoriesToAdd.length}
      </div>
      <ButtonGroup
        variant='contained'
        size='small'
        color={textColor === 'black' ? 'black' : 'white'}
        aria-label='Add the number of items in your collection'
      >
        <Button onClick={addItem}>+</Button>
        <Button onClick={deleteItem}>-</Button>
      </ButtonGroup>
    </div>
  )
}

export default CollectionAddBulkAccessory
