import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import Textfield from '../../components/Forms/Textfield'

import useBodyClass from '../../hooks/useBodyClass'

const initialFormValues = { code: '', new_password: '' }
const formValidation = Yup.object().shape({
  code: Yup.string().required('Verification Code is Required'),
  new_password: Yup.string().required('A New Password is Required')
})

const ResetPasswordForm = ({ passedEmail }) => {
  useBodyClass('o96-bg--space')

  const navigate = useNavigate()

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await Auth.forgotPasswordSubmit(
        passedEmail,
        values.code,
        values.new_password
      )
      setSubmitting(false)
      navigate('/sign-in', { state: { from: '/' } })
    } catch (e) {
      if (e.code === 'CodeMismatchException') {
        setErrors({
          code: `Sorry, the code you've entered does not match the one emailed to you.`
        })
      } else if (e.code === 'LimitExceededException') {
        setErrors({
          code: `Sorry, your password reset attempts with this code have exceeded limits. Please wait some time, send yourself a new code, and try again.`
        })
      } else if (
        e.code === 'InvalidParameterException' ||
        e.code === 'InvalidPasswordException'
      ) {
        setErrors({
          new_password:
            'Sorry, your password must be at least 8 characters long.'
        })
      }
      console.log(e)
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, isValid }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Textfield name='code' type='code' label='Verification Code' />
            </Grid>
            <Grid item xs={12}>
              <Textfield
                name='new_password'
                type='password'
                label='New Password'
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type='submit'
                size='large'
                disabled={isSubmitting || !isValid}
                variant='contained'
              >
                Set New Password
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
