import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'

import { Auth } from 'aws-amplify'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import { Loader } from '../../components/Loader'
import useMediaQuery from '@mui/material/useMediaQuery'

const DeleteUserModal = ({ open, onClose }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [showDeleter, setShowDeleter] = useState(false)
  const { logout } = useUser()
  let navigate = useNavigate()

  const handleDeleteUserAccount = async () => {
    try {
      setShowDeleter(true)
      await Auth.deleteUser()
      logout()
      handleCloseDeleteUser()
      navigate('/')
    } catch (e) {
      console.log(e)
    }
  }

  const handleCloseDeleteUser = () => {
    setShowDeleter(false)
    onClose()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseDeleteUser}
        aria-labelledby='delete-user-modal-title'
        aria-describedby='delete-user-modal-description'
      >
        <Box
          className={`o96-modal__container ${
            isMobile ? 'o96-modal__container--mobile' : ''
          }`}
          sx={{ maxWidth: 750 }}
        >
          <Typography
            id='delete-user-modal-title'
            variant='h5'
            component='h2'
            sx={{ mb: 2 }}
          >
            Delete Account
          </Typography>

          <Typography variant='body1' sx={{ mb: 2 }}>
            We hate to see you go! Please come back any time.
          </Typography>
          <Typography
            id='delete-user-modal-description'
            variant='body2'
            sx={{ mb: 2 }}
          >
            <strong>Note:</strong>{' '}
            <em>
              Deleting your account may result in loss of data. You will not be
              able to partake in surveys, manage a collection, or access parts
              of the site.
            </em>
          </Typography>
          <div
            className={`o96-modal__button-well ${
              isMobile ? 'o96-modal__button-well--mobile' : ''
            }`}
          >
            <Button
              color='primary'
              aria-label='cancel'
              variant='outlined'
              size='large'
              fullWidth={isMobile ? true : false}
              onClick={handleCloseDeleteUser}
            >
              Cancel
            </Button>

            <Button
              onClick={handleDeleteUserAccount}
              color='error'
              aria-label='save figure edits'
              variant='outlined'
              size='large'
              type='submit'
              fullWidth={isMobile ? true : false}
              sx={{ ml: isMobile ? 0 : 2, mb: isMobile ? 2 : 0 }}
              startIcon={<DeleteIcon />}
            >
              Delete Account
            </Button>
          </div>
          {showDeleter && <Loader loaderText='Deleting user...' />}
        </Box>
      </Modal>
    </div>
  )
}

export default DeleteUserModal
