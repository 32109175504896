import React from 'react'
import Textfield from '@mui/material/TextField'
import { useField } from 'formik'

const TextfieldWrapper = ({ name, ...otherProps }) => {
  const [field, mata] = useField(name)

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined'
  }

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true
    configTextfield.helperText = mata.error
  }

  return <Textfield {...configTextfield} />
}
export default TextfieldWrapper
