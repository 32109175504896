import React from 'react'
import { YellowCardback } from '../../components/YellowCardback'
import useBodyClass from '../../hooks/useBodyClass'

const FigureHome = () => {
  useBodyClass('o96-bg--yellow')

  return <YellowCardback />
}

export default FigureHome
