import { Auth } from 'aws-amplify'

export const fetchWithAuthMode = async theFunction => {
  try {
    const returnUser = await Auth.currentAuthenticatedUser()
    const userStatus = returnUser && 'AMAZON_COGNITO_USER_POOLS'
    theFunction(userStatus)
  } catch (e) {
    console.log(e)
    theFunction('AWS_IAM')
  }
}
