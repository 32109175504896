import React from 'react'
import * as d3 from 'd3'
import { useD3 } from '../../../hooks/useD3'
import useMediaQuery from '@mui/material/useMediaQuery'

const HorizontalBarChart = ({ allFiguresWithLikes }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'))
  const width = 900
  const height = isMobile ? 1400 : isTablet ? 800 : 500
  const avatarRadius = isMobile ? 42 : isTablet ? 24 : 16

  const ref = useD3(
    svg => {
      const margin = {
        top: isMobile ? 16 : isTablet ? 24 : 8,
        right: isMobile ? 54 : isTablet ? 38 : 42,
        bottom: isMobile ? 90 : isTablet ? 60 : 48,
        left: isMobile ? 80 : isTablet ? 60 : 48
      }
      const xAxisOffset = isMobile ? 80 : isTablet ? 60 : 42

      const y = d3
        .scaleBand()
        .domain(allFiguresWithLikes.map(d => d.keyName))
        .rangeRound([margin.top, height - margin.bottom])
        .padding(0)

      const x = d3
        .scaleLinear()
        .domain([0, d3.max(allFiguresWithLikes, d => d.likeCount)])
        .rangeRound([margin.left, width - margin.right])

      const xAxis = g =>
        g
          .attr('transform', `translate(0,${height - xAxisOffset})`)
          .call(d3.axisBottom(x))

      const yAxis = g =>
        g
          .attr('transform', `translate(${avatarRadius + margin.left},0)`)
          .call(
            d3
              .axisRight(y)
              .tickFormat((d, i) => allFiguresWithLikes[i].shortName)
          )
          .call(g => g.select('.domain').remove())

      svg.select('.x-axis').call(xAxis)
      svg.select('.y-axis').call(yAxis)

      svg
        .select('.y-axis')
        .selectAll('.tick')
        .data(allFiguresWithLikes)
        .attr('color', d => d.textColor)
        .attr('font-weight', 500)
        .attr('font-size', isMobile ? 30 : isTablet ? 18 : 12)

      svg
        .select('.y-axis')
        .selectAll('line')
        .remove()

      svg
        .select('.x-axis')
        .selectAll('.tick')
        .attr('font-size', isMobile ? 30 : isTablet ? 18 : 10)

      svg
        .select('.x-axis-label-text')
        .attr('x', `${width / 2}`)
        .attr('y', `${height}`)
        .join('text')
        .attr('font-size', isMobile ? 30 : isTablet ? 18 : 10)
        .attr('fill', 'white')
        .attr('text-anchor', 'middle')
        .text('Total Number of Likes')

      svg
        .select('.plot-area')
        .selectAll('.bar')
        .data(allFiguresWithLikes)
        .join('rect')
        .attr('class', 'bar')
        .attr('fill', d => d.hexColor)
        .attr('y', d => y(d.keyName) + y.bandwidth() / 4)
        .attr('height', y.bandwidth() / 2)
        .attr('x', `${margin.left}`)
        .attr('width', d => x(0))

      svg
        .selectAll('rect')
        .transition()
        .duration(800)
        .attr('width', d => x(d.likeCount) - `${margin.left}`)
        .delay(function (d, i) {
          return i * 100
        })

      svg
        .select('.y-axis-avatars')
        .selectAll('.mini-portrait')
        .data(allFiguresWithLikes)
        .join('defs')
        .append('pattern')
        .attr('id', d => d.keyName)
        .attr('x', '0')
        .attr('y', '0')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', `0 0 ${avatarRadius * 2} ${avatarRadius * 2}`)
        .append('svg:image')
        .attr('x', '0')
        .attr('y', '0')
        .attr('width', avatarRadius * 2)
        .attr('height', avatarRadius * 2)
        .attr(
          'xlink:href',
          d => 'img/collection/mini-portraits/' + d.linkPath + '.png'
        )

      svg
        .select('.y-axis-avatars')
        .selectAll('.avatar-circle')
        .data(allFiguresWithLikes)
        .join('circle')
        .attr('class', 'avatar-circle')
        .attr('fill', d => d.hexColor)
        .attr('cy', d => y(d.keyName) + y.bandwidth() / 2)
        .attr('cx', `${margin.left}`)
        .attr('r', avatarRadius)

      svg
        .select('.y-axis-avatar-photos')
        .selectAll('.avatar-photo')
        .data(allFiguresWithLikes)
        .join('circle')
        .attr('class', 'avatar-photo')
        .attr('fill', d => 'url(#' + d.keyName + ')')
        .attr('cy', d => y(d.keyName) + y.bandwidth() / 2)
        .attr('cx', `${margin.left}`)
        .attr('r', avatarRadius)
    },
    [allFiguresWithLikes.length, isMobile, isTablet]
  )
  return (
    <svg
      ref={ref}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio='xMinYMin meet'
      style={{
        marginRight: '0px',
        marginLeft: '0px'
      }}
    >
      <g className='plot-area' />
      <g className='x-axis' />
      <text className='x-axis-label-text' />
      <g className='y-axis-avatars' />
      <g className='y-axis-avatar-photos' />
      <g className='y-axis' />
    </svg>
  )
}

export default HorizontalBarChart
