import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 8,
    top: 53,
    border: `2px solid ${theme.palette.grey[900]}`,
    padding: '0 4px',
    fontWeight: `${theme.typography.fontWeightMedium}`,
    backgroundColor: `${theme.palette.grey[600]}`
  }
}))

const CollectionGridColumnAvatar = ({
  maker,
  hexColor,
  name,
  linkPath,
  directoryPath
}) => {
  if (maker === 'custom') {
    return (
      <Tooltip
        title='Custom Figure'
        arrow
        placement='bottom'
        color='black'
        PopperProps={{
          popperOptions: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [62, -8]
                }
              }
            ]
          }
        }}
      >
        <StyledBadge badgeContent={'c'}>
          <Avatar
            sx={{ bgcolor: hexColor, width: 65, height: 65 }}
            alt={name && name.charAt(0)}
            src={`${directoryPath}${linkPath}.png`}
          />
        </StyledBadge>
      </Tooltip>
    )
  } else {
    return (
      <Avatar
        sx={{ bgcolor: hexColor, width: 65, height: 65 }}
        alt={name && name.charAt(0)}
        src={`${directoryPath}${linkPath}.png`}
      />
    )
  }
}

export default CollectionGridColumnAvatar
