import React from 'react'
import { useParams } from 'react-router-dom'
import { PillButton } from '../../components/PillButton'

const FigureSide = ({ completeFigures }) => {
  const { figureId } = useParams()
  const currentFigure = completeFigures.find(x => x.linkPath === figureId)

  return (
    <aside className='o96-side-bar'>
      <h2 className='o96-heading--content--main'>{currentFigure?.name}</h2>
      <div className='o96-side-bar__two-col'>
        <div className='o96-side-bar__content'>
          <h6>Manufacturer</h6>
          <label>Kenner</label>
        </div>
        <div className='o96-side-bar__content'>
          <h6>Initial Release Year</h6>
          <label>{currentFigure?.initialReleaseYear}</label>
        </div>
      </div>
      <div className='o96-side-bar__two-col'>
        <div className='o96-side-bar__content'>
          <h6>Initial Release Wave</h6>
          <label>{currentFigure?.initialReleaseWaveLabel}</label>
        </div>
      </div>
      {currentFigure?.accessories?.length > 0 && (
        <div className='o96-side-bar__content'>
          <h6>Accessories</h6>
          <div>
            {currentFigure?.accessories.map(({ name }, i) => {
              if (i === 0) {
                return <span key={i}>{name}</span>
              } else {
                return <span key={i}>, {name}</span>
              }
            })}
          </div>
        </div>
      )}
      <div className='o96-side-bar__content'>
        <PillButton
          currentFigure={currentFigure}
          buttonPath={currentFigure?.ebayLink}
          buttonText={`Find ${currentFigure?.shortName} on eBay`}
        />
      </div>
    </aside>
  )
}
export default FigureSide
