import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Link from '@mui/material/Link'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useGetFigures } from '../../hooks/useGetFigures'

const YellowCardback = () => {
  const { completeFigures } = useGetFigures()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <div className='o96-body o96-body--with-footer '>
      {!isMobile ? (
        <Typography
          variant='h4'
          component='div'
          align='right'
          sx={{
            fontFamily: 'Public Sans',
            fontWeight: 700,
            color: 'Black',
            p: '1.5rem 3.5rem 0'
          }}
        >
          Collect all{' '}
          <Tooltip
            title='More to come&hellip;'
            arrow
            PopperProps={{
              popperOptions: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -8]
                    }
                  }
                ]
              }
            }}
          >
            <Link className='o96-h1-tooltip-link'>12!</Link>
          </Tooltip>
        </Typography>
      ) : (
        <Typography
          variant='h5'
          component='div'
          align='center'
          sx={{
            fontFamily: 'Public Sans',
            fontWeight: 700,
            color: 'Black',
            p: '1.5rem 2rem 0'
          }}
        >
          Collect all 12!
        </Typography>
      )}
      <ul className='o96-figure__list'>
        {completeFigures.map(({ name, linkPath }) => {
          return (
            <li key={linkPath} className='o96-figure__list-item'>
              <RouterLink
                to={`/figures/${linkPath}`}
                className='o96-figure__list-item-link'
              >
                <img
                  src={`/img/figures/${linkPath}/1.png`}
                  alt={name}
                  className='o96-figure__list-item-image'
                />
              </RouterLink>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default YellowCardback
