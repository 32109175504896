import React, { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import {
  deleteAccessory,
  deleteFigure,
  updateAccessory
} from '../../graphql/mutations'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import { Loader } from '../../components/Loader'
import useMediaQuery from '@mui/material/useMediaQuery'

const CollectionDeleteFigureModal = ({
  rowParamsFigure,
  open,
  onClose,
  collection,
  setStatus,
  setFetchLatestCollection
}) => {
  const [showDeleter, setShowDeleter] = useState(false)
  const [showDeleterSingle, setShowDeleterSingle] = useState(false)
  const [ownedAccessories, setOwnedAccessories] = useState([])
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    const currentlyOwned = collection?.accessories?.items.filter(
      x => x.figureAccessoriesId === rowParamsFigure?.id
    )
    setOwnedAccessories(currentlyOwned)
  }, [collection?.accessories?.items, rowParamsFigure?.id])

  const handleDeleteFigure = async () => {
    try {
      await API.graphql(
        graphqlOperation(deleteFigure, {
          input: { id: rowParamsFigure.id }
        })
      )
      setFetchLatestCollection(x => !x)
      setStatus('fetching')
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }

  const handleUpdateAccessories = async () => {
    try {
      await Promise.all(
        ownedAccessories.map(accessory =>
          API.graphql(
            graphqlOperation(updateAccessory, {
              input: { id: accessory.id, figureAccessoriesId: null }
            })
          )
        )
      )
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteAccessories = async () => {
    try {
      await Promise.all(
        ownedAccessories.map(accessory =>
          API.graphql(
            graphqlOperation(deleteAccessory, {
              input: { id: accessory.id }
            })
          )
        )
      )
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteAll = () => {
    setShowDeleter(true)
    handleDeleteAccessories()
    handleDeleteFigure()
  }

  const handleDeleteFigOnly = () => {
    setShowDeleterSingle(true)
    handleUpdateAccessories()
    handleDeleteFigure()
  }

  const handleClose = () => {
    setShowDeleter(false)
    setShowDeleterSingle(false)
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-figure-modal-title'
      aria-describedby='delete-figure-modal-description'
    >
      <Box
        className={`o96-modal__container ${
          isMobile ? 'o96-modal__container--mobile' : ''
        }`}
        sx={{ maxWidth: 750 }}
      >
        <Typography
          id='delete-figure-modal-title'
          variant='h5'
          component='h2'
          sx={{ mb: 2 }}
        >
          Delete {rowParamsFigure?.row.name}
        </Typography>

        <Typography
          id='delete-figure-modal-description'
          variant='subtitle1'
          sx={{ mb: 5 }}
        >
          Deleting {ownedAccessories?.length > 0 ? 'items ' : 'the figure '}
          will remove {ownedAccessories?.length > 0 ? 'them ' : 'it '} from the
          collection. You can easily add items back at any time by clicking the{' '}
          {isMobile ? <span> "+" </span> : <span>"+ Add Items"</span>} button.
        </Typography>

        <Box
          className={`o96-modal__button-well ${
            isMobile
              ? 'o96-modal__button-well--mobile o96-modal__button-well--mobile-three-button'
              : ''
          }`}
        >
          <div style={{ textAlign: 'left' }}>
            <Button
              color='primary'
              aria-label='cancel'
              variant='outlined'
              size='large'
              fullWidth={isMobile ? true : false}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
          <div className='o96-modal__button-well--multiple-primary'>
            <Button
              onClick={handleDeleteFigOnly}
              color='error'
              aria-label='delete figure only'
              variant='outlined'
              size='large'
              type='submit'
              fullWidth={isMobile ? true : false}
              startIcon={<DeleteIcon />}
            >
              Delete Figure {ownedAccessories?.length > 0 && 'Only'}
            </Button>
            {ownedAccessories?.length > 0 && (
              <Button
                onClick={handleDeleteAll}
                color='error'
                aria-label='delete figure and associated accessories'
                variant='outlined'
                fullWidth={isMobile ? true : false}
                size='large'
                type='submit'
                sx={{ ml: { xs: 0, sm: 2 } }}
                startIcon={<DeleteIcon />}
              >
                Delete Figure &amp; Accessories
              </Button>
            )}
          </div>
        </Box>
        {showDeleter && <Loader loaderText='Deleting Items...' />}
        {showDeleterSingle && <Loader loaderText='Deleting Figure...' />}
      </Box>
    </Modal>
  )
}

export default CollectionDeleteFigureModal
