// Framework Imports
import React, { useState } from 'react'

// Framework Component Imports
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro'

// Custom Component Imports
import { Loader } from '../../components/Loader'
import { CollectionGridColumnAvatar } from '../../components/CollectionGridColumnAvatar'
import { CollectionEditAccessoryModal } from '../../components/CollectionEditAccessoryModal'
import { CollectionDeleteAccessoryModal } from '../../components/CollectionDeleteAccessoryModal'

// Data Fetch Hook Imports
import { useGetCollection } from '../../hooks/useGetCollection'
import { useGetFigures } from '../../hooks/useGetFigures'

const CollectionGridAccessories = () => {
  const [fetchLatestCollection, setFetchLatestCollection] = useState(true)
  const { collection, status, setStatus } = useGetCollection(
    fetchLatestCollection
  )
  const { completeFigures } = useGetFigures()

  const [openEditAccessory, setOpenEditAccessory] = useState(false)
  const [openDeleteAccessory, setOpenDeleteAccessory] = useState(false)
  const [rowParamsAccessory, setRowParamsAccessory] = useState()
  const ownedAccessories = collection.accessories?.items
  const looseAccessories = ownedAccessories?.filter(
    itm => !itm.figureAccessoriesId || itm.figureAccessoriesId === null
  )

  const completeAccessories = completeFigures
    ?.map(cFig =>
      cFig.accessories?.map(item =>
        Object.assign({}, item, { hexColor: cFig.hexColor })
      )
    )
    .flat()

  const looseAccessoriesWithDetails = looseAccessories?.map(itm => ({
    ...completeAccessories?.find(
      item => item?.keyName === itm?.keyName && item
    ),
    ...itm
  }))

  // Modal Handlers
  const handleOpenEditAccessory = params => {
    setRowParamsAccessory(params)
    setOpenEditAccessory(true)
  }

  const handleCloseEditAccessory = () => {
    setOpenEditAccessory(false)
  }

  const handleOpenDeleteAccessory = params => {
    setRowParamsAccessory(params)
    setOpenDeleteAccessory(true)
  }

  const handleCloseDeleteAccessory = () => {
    setOpenDeleteAccessory(false)
  }

  // Properties for Modals
  const sharedProps = {
    setStatus: setStatus,
    setFetchLatestCollection: setFetchLatestCollection,
    rowParamsAccessory: rowParamsAccessory
  }

  const deleteModalProps = {
    open: openDeleteAccessory,
    onClose: handleCloseDeleteAccessory
  }

  const editModalProps = {
    open: openEditAccessory,
    onClose: handleCloseEditAccessory
  }

  // Columns Definitions
  const columns = [
    {
      field: 'avatar',
      headerName: '',
      align: 'center',
      width: 65,
      renderCell: params => {
        return (
          <CollectionGridColumnAvatar
            maker={params.row.maker}
            hexColor={params.row.hexColor}
            name={params.row.name}
            directoryPath={'/img/collection/accessories-cropped/'}
            linkPath={params.row.linkPath}
          />
        )
      }
    },
    { field: 'name', headerName: 'Accessory', width: 290 },
    {
      field: 'maker',
      headerName: 'Type',
      width: 150,
      renderCell: params => {
        if (params.row.maker === 'custom') {
          return 'Custom'
        } else if (params.row.maker === 'vintageKenner') {
          return 'Vintage Kenner'
        } else if (params.row.maker === 'refurbished') {
          return 'refurbished'
        }
      }
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 450
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: params => [
        <GridActionsCellItem
          onClick={() => handleOpenEditAccessory(params)}
          icon={<EditIcon />}
          label='Edit'
        />,
        <GridActionsCellItem
          onClick={() => handleOpenDeleteAccessory(params)}
          icon={<DeleteIcon />}
          label='Delete'
        />
      ]
    }
  ]

  return (
    <>
      {status === 'fetching' && (
        <Loader loaderText='Loading Loose Accessories...' />
      )}
      {status === 'fetched' && (
        <>
          <DataGridPro
            rows={looseAccessoriesWithDetails}
            columns={columns}
            density='comfortable'
            pageSize={50}
            rowThreshold={0}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }]
              },
              pinnedColumns: { right: ['actions'] }
            }}
            hideFooter
          />
          <CollectionEditAccessoryModal {...sharedProps} {...editModalProps} />
          <CollectionDeleteAccessoryModal
            {...sharedProps}
            {...deleteModalProps}
          />
        </>
      )}
    </>
  )
}

export default CollectionGridAccessories
