import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { Loader } from '../../components/Loader'
import useMediaQuery from '@mui/material/useMediaQuery'

import { CollectionAddBulkCard } from '../CollectionAddBulkCard'
import { CollectionAddBulkDialog } from '../CollectionAddBulkDialog'
import { useGetCollection } from '../../hooks/useGetCollection'
import { useGetFigures } from '../../hooks/useGetFigures'
import { useUser } from '../../hooks/useUser'

const CollectionAddBulk = () => {
  const [wasSubmitted, setWasSubmitted] = useState(false)
  const [totalFiguresAdded, setTotalFiguresAdded] = useState([])
  const [totalAccessoriesAdded, setTotalAccessoriesAdded] = useState([])
  const [
    totalAccessoriesWithFigsAdded,
    setTotalAccessoriesWithFigsAdded
  ] = useState([])

  const [open, setOpen] = useState(false)
  const { collection } = useGetCollection()
  const { completeFigures, figureStatus } = useGetFigures()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { user } = useUser()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function dynamicSort (property) {
    var sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }
    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property])
      } else {
        return a[property].localeCompare(b[property])
      }
    }
  }

  const sortedCompleteFigures = completeFigures?.sort(dynamicSort('name'))

  useEffect(() => {
    setWasSubmitted(false)
  }, [wasSubmitted])

  const handleSubmit = async event => {
    event.preventDefault()
    setWasSubmitted(true)
  }

  if (user === 'unauthenticated') {
    return <Navigate to='/collection' replace />
  }

  return (
    <>
      <Container
        sx={{
          paddingTop: '68px'
        }}
      >
        {figureStatus === 'fetching' && (
          <Loader loaderText='Loading Figures and Accessories...' />
        )}
        <Box className='o96-box--main'>
          <form noValidate onSubmit={handleSubmit}>
            <AppBar
              position='static'
              sx={{
                borderRadius: '.4875rem .4875rem 0 0'
              }}
            >
              <Toolbar>
                <div className='o96-heading--with-subtitle'>
                  <Typography variant='h6' component='div'>
                    Add Bulk Items
                  </Typography>
                  {isMobile ? (
                    <></>
                  ) : (
                    <Typography variant='subtitle2'>
                      Enter the number of items you have, and add them to the
                      collection
                    </Typography>
                  )}
                </div>
                <Button
                  color='secondary'
                  aria-label='add'
                  size='large'
                  type='submit'
                  variant='contained'
                  onClick={handleClickOpen}
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              </Toolbar>
            </AppBar>

            <div className='o96-box--bulk-add'>
              {sortedCompleteFigures.map(
                (
                  {
                    keyName,
                    textColor,
                    hexColor,
                    name,
                    linkPath,
                    variantType,
                    variantName,
                    accessories,
                    accessoryVariants
                  },
                  idx
                ) => {
                  return (
                    <CollectionAddBulkCard
                      {...{
                        keyName,
                        linkPath,
                        textColor,
                        hexColor,
                        name,
                        variantType,
                        variantName,
                        accessories,
                        accessoryVariants
                      }}
                      collection={collection}
                      wasSubmitted={wasSubmitted}
                      setTotalFiguresAdded={setTotalFiguresAdded}
                      setTotalAccessoriesAdded={setTotalAccessoriesAdded}
                      totalAccessoriesAdded={totalAccessoriesAdded}
                      setTotalAccessoriesWithFigsAdded={
                        setTotalAccessoriesWithFigsAdded
                      }
                      totalAccessoriesWithFigsAdded={
                        totalAccessoriesWithFigsAdded
                      }
                      totalFiguresAdded={totalFiguresAdded}
                      key={idx}
                    />
                  )
                }
              )}
            </div>
          </form>
        </Box>
        <CollectionAddBulkDialog
          open={open}
          onClose={handleClose}
          setTotalFiguresAdded={setTotalFiguresAdded}
          setTotalAccessoriesAdded={setTotalAccessoriesAdded}
          totalAccessoriesAdded={totalAccessoriesAdded}
          setTotalAccessoriesWithFigsAdded={setTotalAccessoriesWithFigsAdded}
          totalAccessoriesWithFigsAdded={totalAccessoriesWithFigsAdded}
          totalFiguresAdded={totalFiguresAdded}
        />
      </Container>
    </>
  )
}

export default CollectionAddBulk
