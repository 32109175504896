import React from 'react'

// Custom Components
import { Loader } from '../../components/Loader'
import { CollectionGridPage } from '../../pages/CollectionGridPage'
import { CollectionPrompt } from '../../components/CollectionPrompt'

// Custom Hooks
import useBodyClass from '../../hooks/useBodyClass'
import { useUser } from '../../hooks/useUser'
import { useGetCollection } from '../../hooks/useGetCollection'

const CollectionPage = () => {
  useBodyClass('o96-bg--space')
  const { status } = useGetCollection()
  const { user } = useUser()

  return (
    <>
      {user?.username && status === 'fetching' && (
        <Loader loaderText='Loading Collection...' />
      )}
      {user?.username && status === 'noCollectionAdded' && (
        <CollectionPrompt user={user} />
      )}
      {user?.username && status === 'fetched' && <CollectionGridPage />}
      {user === 'unauthenticated' && <CollectionPrompt user={user} />}
    </>
  )
}
export default CollectionPage
