import React, { useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createFigure } from '../../graphql/mutations'

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

const CollectionAddBulkFigure = ({
  name,
  keyName,
  textColor,
  wasSubmitted,
  collection,
  figuresToAdd,
  setFiguresToAdd,
  setFiguresHaveAdded,
  noAccessories,
  totalFiguresAdded,
  totalAccessoriesAdded
}) => {
  const collectionId = collection?.id

  useEffect(() => {
    setFiguresHaveAdded(false)
  }, [wasSubmitted, setFiguresHaveAdded])

  const addFiguresToCollection = async () => {
    try {
      await Promise.all(
        figuresToAdd.map(() =>
          API.graphql(
            graphqlOperation(createFigure, {
              input: {
                keyName: keyName,
                collectionFiguresId: collectionId
              }
            }),
            totalFiguresAdded.push(keyName)
          )
        )
      )
      setFiguresHaveAdded(true)
      if (noAccessories) {
        setFiguresToAdd([])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const addItem = async () => {
    figuresToAdd.length < 50 && setFiguresToAdd([...figuresToAdd, keyName])
  }
  const deleteItem = async () => {
    figuresToAdd.pop()
    setFiguresToAdd([...figuresToAdd])
  }

  if (wasSubmitted && figuresToAdd.length > 0) {
    addFiguresToCollection()
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: '1.5rem'
      }}
    >
      <div
        style={{
          background:
            textColor === 'black'
              ? 'rgba(255, 255, 255, .2)'
              : 'rgba(0, 0, 0, .1)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '.5rem'
        }}
      >
        <div
          style={{
            color: textColor === 'black' ? 'black' : 'white',
            fontWeight: '500',
            padding: '.25rem'
          }}
        >
          {figuresToAdd.length}
        </div>

        <ButtonGroup
          variant='contained'
          size='small'
          color={textColor === 'black' ? 'black' : 'white'}
          aria-label='Add and delete collection items'
        >
          <Button onClick={addItem}>+</Button>
          <Button onClick={deleteItem}>-</Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default CollectionAddBulkFigure
