import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Container from '@mui/material/Container'
import AddIcon from '@mui/icons-material/Add'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Footer } from '../../components/Footer'

const CollectionGridPage = () => {
  const [collectionView, setCollectionView] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    let path = location.pathname
    if (path === '/collection') navigate('/collection/figures-with-accessories')
    if (path === '/collection/figures-with-accessories')
      setCollectionView('figures-with-accessories')
    else if (path.startsWith('/collection/loose-accessories'))
      setCollectionView('loose-accessories')
  }, [location.pathname, navigate])

  const handleGridChange = (event, newCollectionFilter) => {
    if (newCollectionFilter !== null) {
      setCollectionView(newCollectionFilter)
      navigate(newCollectionFilter)
    }
  }

  return (
    <>
      <Container
        sx={{
          paddingTop: '68px'
        }}
      >
        <Box className='o96-box--main'>
          <AppBar
            position='static'
            sx={{
              borderRadius: '.4875rem .4875rem 0 0'
            }}
          >
            <Toolbar
              sx={{
                justifyContent: 'space-between',
                borderBottom: '1px solid rgba(81,81,81,1)'
              }}
            >
              <ToggleButtonGroup
                variant='primary'
                size='small'
                value={collectionView}
                exclusive
                onChange={handleGridChange}
                aria-label='figures / accessories toggle'
                sx={{ my: 2, justifySelf: 'center' }}
              >
                <ToggleButton value='figures-with-accessories'>
                  Figures with Accessories
                </ToggleButton>
                <ToggleButton value='loose-accessories'>
                  Loose Accesories
                </ToggleButton>
              </ToggleButtonGroup>
              {isMobile ? (
                <Button
                  component={Link}
                  aria-label='Add Items'
                  size='large'
                  color='primary'
                  to='/add-bulk'
                  variant='contained'
                  sx={{ py: 2.1375, ml: 3 }}
                >
                  <AddIcon />
                </Button>
              ) : (
                <Button
                  component={Link}
                  variant='contained'
                  aria-label='Add Items'
                  size='small'
                  startIcon={<AddIcon />}
                  to='/add-bulk'
                >
                  Add Items
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <div
            className={`o96-grid-wrapper ${
              isMobile ? 'o96-grid-wrapper--mobile' : ''
            }`}
          >
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }}>
                <Outlet />
              </div>
            </div>
          </div>
        </Box>
        <div style={{ padding: '1rem 0', width: '100%' }}>
          <Footer />
        </div>
      </Container>
    </>
  )
}

export default CollectionGridPage
