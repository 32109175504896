import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'

import useBodyClass from '../../hooks/useBodyClass'
import { useUser } from '../../hooks/useUser'

import { Footer } from '../../components/Footer'
import { DeleteUserModal } from '../../components/DeleteUserModal'

const SettingsPage = () => {
  useBodyClass('o96-bg--space')
  const { user } = useUser()
  const [openDeleteUser, setOpenDeleteUser] = useState(false)

  const handleOpenDeleteUser = () => {
    setOpenDeleteUser(true)
  }

  const handleCloseDeleteUser = () => {
    setOpenDeleteUser(false)
  }

  const deleteUserProps = {
    open: openDeleteUser,
    onClose: handleCloseDeleteUser
  }

  if (user === 'unauthenticated') {
    return <Navigate to='/' replace />
  }

  return (
    <>
      <div className='o96-body o96-body--with-centered-footer'>
        <Container
          sx={{
            paddingTop: '2rem'
          }}
        >
          <Box
            className='o96-box--main'
            sx={{
              pb: '2rem'
            }}
          >
            <AppBar
              position='static'
              sx={{
                borderRadius: '.4875rem .4875rem 0 0',
                display: 'flex',
                alignItems: 'center',
                padding: '.75rem 0'
              }}
            >
              <Typography variant='h5' component='div'>
                Settings
              </Typography>
            </AppBar>
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Typography variant='h6'>Username/Email Address</Typography>
              <Divider
                sx={{
                  my: 1,
                  width: '100%',
                  borderColor: 'rgba(255, 255, 255, .45)'
                }}
              />
              <Typography variant='body1' sx={{ mt: 1, mb: 5 }}>
                {user?.attributes.email}
              </Typography>
              <Typography variant='h6'>Delete Account</Typography>
              <Divider
                sx={{
                  my: 1,
                  mb: 2,
                  width: '100%',
                  borderColor: 'rgba(255, 255, 255, .45)'
                }}
              />
              <Button
                variant='contained'
                color='error'
                onClick={handleOpenDeleteUser}
              >
                Delete Account
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
      <div style={{ padding: '0 0 .5rem', width: '100%' }}>
        <Footer theme={`centered`} />
      </div>
      <DeleteUserModal {...deleteUserProps} />
    </>
  )
}

export default SettingsPage
