import React, { useState, useEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Chip from '@mui/material/Chip'

const CollectionDetailToggle = props => {
  const { id, value: isExpanded, allAccessories, collection } = props
  const [ownedAccessories, setOwnedAccessories] = useState([])

  useEffect(() => {
    const currentlyOwned = collection?.accessories.items.filter(
      x => x.figureAccessoriesId === id
    )
    setOwnedAccessories(currentlyOwned)
  }, [collection?.accessories.items, id])

  return (
    <>
      {allAccessories?.length > 0 && (
        <>
          <Chip
            label={`${ownedAccessories?.length} of ${allAccessories?.length}`}
            icon={
              <ExpandMoreIcon
                sx={{
                  transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                  transition: theme =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest
                    })
                }}
                fontSize='inherit'
              />
            }
            variant='outlined'
            size='small'
            color={
              ownedAccessories?.length < allAccessories?.length
                ? 'warning'
                : 'success'
            }
            clickable
          />
        </>
      )}
    </>
  )
}

export default CollectionDetailToggle
