import React from 'react'
import useBodyClass from '../../hooks/useBodyClass'
import Typography from '@mui/material/Typography'
import { Footer } from '../../components/Footer'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DoubleArrowTwoToneIcon from '@mui/icons-material/DoubleArrowTwoTone'
import useMediaQuery from '@mui/material/useMediaQuery'

const HomePage = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isDesktop = useMediaQuery(theme => theme.breakpoints.down('lg'))
  useBodyClass(['o96-bg--space', 'o96-home-page'])
  return (
    <>
      <Box
        sx={{ flexGrow: 1 }}
        className={
          isMobile
            ? 'mobile'
            : isTablet
            ? 'tablet'
            : isDesktop
            ? 'desktop'
            : 'desktop-xl'
        }
      >
        <Grid container sx={{ mt: 0 }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={7}
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
            className='o96-grid-item--with-border-bottom'
          >
            <Typography
              sx={{ mt: 1, mb: 0, fontWeight: '200', maxWidth: '640px' }}
              variant='h3'
              align='center'
              component='div'
              className='o96-heading--hero'
            >
              Vintage and Vintage-Style Star Wars Action Figures
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            className='o96-grid-item--with-border-bottom o96-container--hero-img'
          >
            <div className='o96-figure__container o96-figure__container--banner'>
              <div className='o96-figure__border--outer'>
                <div className='o96-figure__border--inner'>
                  <div className='o96-figure__border--outer2'>
                    <div className='o96-figure__border--inner2'>
                      <div className='o96-figure__frame o96-bg--cyan'>
                        <img
                          height='400px'
                          src='/img/cropped-figures/princess-leia-organa/1.png'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='o96-grid-item--with-border-bottom o96-parallax__container o96-parallax__container--figz o96-grid-row--img-bullets'
          >
            <img
              height='500px'
              src='/img/yellow-card-4-x-3.png'
              alt='Vintage Kenner First 12 Figures'
              className='o96-parallax__item o96-parallax__item-catalogue o96-img--figures'
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='o96-grid-item--with-border-bottom o96-grid-row--img-bullets'
          >
            <Box
              sx={{ mt: 4, mb: 3, ml: 4, mr: 4 }}
              className='o96-bullet-list__container'
            >
              <Typography sx={{ mb: 0 }} variant='h5' component='div'>
                A Catalog of Figures
              </Typography>
              <Typography sx={{ mb: 0 }} variant='body1' component='div'>
                360° interactive views &amp; links to find:
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: '2rem' }}>
                    <DoubleArrowTwoToneIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Vintage Kenner (1977-1985) ' />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: '2rem' }}>
                    <DoubleArrowTwoToneIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Retro Collection (2019 - Present)' />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: '2rem' }}>
                    <DoubleArrowTwoToneIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Custom Figures in the vintage-style' />
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='o96-grid-item--with-border-bottom o96-grid-row--bullets-img o96-grid-row--reorder-mobile-2'
          >
            <Box
              sx={{ mt: 4, mb: 3, ml: 4, mr: 4 }}
              className='o96-bullet-list__container'
            >
              <Typography sx={{ mb: 0 }} variant='h5' component='div'>
                Charts &amp; Graphs
              </Typography>
              <Typography sx={{ mb: 0 }} variant='body1' component='div'>
                Fun and interesting info about the figures
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: '2rem' }}>
                    <DoubleArrowTwoToneIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Vote for your favorites' />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: '2rem' }}>
                    <DoubleArrowTwoToneIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Participate in surveys' />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: '2rem' }}>
                    <DoubleArrowTwoToneIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Explore data visualizations' />
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='o96-grid-item--with-border-bottom o96-parallax__container  o96-parallax__container--chart o96-grid-row--bullets-img o96-grid-row--reorder-mobile-1'
          >
            <img
              height='500px'
              src='/img/likes-chart.png'
              alt='Figure Likes Bar Chart'
              className='o96-parallax__item o96-parallax__item-catalogue o96-img--chart'
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='o96-grid-item--with-border-bottom o96-parallax__container  o96-parallax__container--tools o96-grid-row--img-bullets'
          >
            <img
              height='500px'
              src='/img/collection-tools.png'
              alt='Figure Likes Bar Chart'
              className='o96-parallax__item o96-parallax__item-catalogue o96-img--tools'
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className='o96-grid-item--with-border-bottom o96-grid-row--img-bullets'
          >
            <Box
              sx={{ mt: 4, mb: 3, ml: 4, mr: 4 }}
              className='o96-bullet-list__container'
            >
              <Typography sx={{ mb: 0 }} variant='h5' component='div'>
                Tools for Collecting
              </Typography>
              <Typography sx={{ mb: 0 }} variant='body1' component='div'>
                Manage and track your own collection
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: '2rem' }}>
                    <DoubleArrowTwoToneIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Easily find figures you need' />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: '2rem' }}>
                    <DoubleArrowTwoToneIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Rate the condition of items' />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: '2rem' }}>
                    <DoubleArrowTwoToneIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='View detailed lists of your collection' />
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <div style={{ padding: '1rem 2rem .25rem', width: '100%' }}>
        <Footer />
      </div>
    </>
  )
}
export default HomePage
