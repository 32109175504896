import React from 'react'

import { Figure } from '../../components/Figure'
import { FigureSide } from '../../components/FigureSide'
import { FigureSideNav } from '../../components/FigureSideNav'

import useBodyClass from '../../hooks/useBodyClass'
import { useGetFigures } from '../../hooks/useGetFigures'

const FiguresPage = () => {
  useBodyClass('o96-bg--space')
  const { completeFigures } = useGetFigures()

  return (
    <div className='o96-body o96-body--grid o96-body--3-col'>
      <FigureSideNav />
      <Figure completeFigures={completeFigures} />
      <FigureSide completeFigures={completeFigures} />
    </div>
  )
}

export default FiguresPage
