import React from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import LaunchIcon from '@mui/icons-material/Launch'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { DataGridPro } from '@mui/x-data-grid-pro'

const CollectionItemDetailRow = ({ row, collection, hexColor }) => {
  const axDetails = row.accessories
  const ownedAx = collection.accessories.items.filter(
    x => x.figureAccessoriesId === row.id
  )
  const ownedAxWithDetails = ownedAx?.map(itm => ({
    ...itm,
    ...axDetails.find(item => item.keyName === itm.keyName && item)
  }))

  const missingAccessories = axDetails?.filter(
    itm => !ownedAx.find(item => itm.keyName === item.keyName)
  )

  const allAccessories =
    missingAccessories.length > 0
      ? [...ownedAxWithDetails, ...missingAccessories]
      : ownedAxWithDetails

  const columns = [
    {
      field: 'owned',
      headerName: 'Owned',
      width: 70,
      align: 'center',
      renderCell: params => {
        return (
          params.row.owner && (
            <CheckCircleIcon
              fontSize='small'
              color='success'
              className='avatar-check-circle'
            />
          )
        )
      }
    },
    {
      field: 'avatar',
      headerName: '',
      width: 52,

      align: 'center',
      renderCell: params => {
        return (
          <Avatar
            sx={{
              background: hexColor,
              width: 52,
              height: 52,
              borderRadius: 0
            }}
            alt={params?.row.name && params.row.name.charAt(0)}
            src={`/img/collection/accessories-cropped/${params.row.linkPath}.png`}
          />
        )
      }
    },
    {
      field: 'name',
      headerName: 'Accessory',
      width: 362,
      renderCell: params => {
        if (params.row.owner) {
          return params.row.name
        } else {
          return (
            <Button
              sx={{ my: 2 }}
              variant='outlined'
              size='small'
              href={params.row.ebayLink}
              color='primary'
              rel='noreferrer'
              target='_blank'
              endIcon={<LaunchIcon />}
            >
              {`Find ${params.row.shortName} on Ebay`}
            </Button>
          )
        }
      }
    },
    { field: 'note', headerName: 'Note', width: 400 }
  ]

  return (
    <Stack
      sx={{
        height: 1,
        boxSizing: 'border-box',
        position: 'sticky',
        left: 0,
        width: '100%',
        borderBottom: '1px solid rgba(81,81,81,1)'
      }}
      direction='column'
    >
      <Paper sx={{ flex: 1, mx: 'auto', width: '100%', mt: '-1px' }}>
        <Stack direction='column' sx={{ height: '100%' }}>
          <DataGridPro
            density='standard'
            headerHeight={0}
            columns={columns}
            rows={allAccessories}
            disableSelectionOnClick
            hideFooter
          />
        </Stack>
      </Paper>
    </Stack>
  )
}

export default CollectionItemDetailRow
