import React from 'react'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import useMediaQuery from '@mui/material/useMediaQuery'

import useBodyClass from '../../hooks/useBodyClass'
import { useGetFigures } from '../../hooks/useGetFigures'
import { useGetLikes } from '../../hooks/useGetLikes'

import { Loader } from '../../components/Loader'
import { HorizontalBarChart } from '../../components/charts/HorizontalBarChart'
import { Footer } from '../../components/Footer'

const ChartsPage = () => {
  useBodyClass('o96-bg--space')
  const { completeFigures } = useGetFigures()
  const { allFigureLikes, likesStatus } = useGetLikes()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const allFiguresWithLikes = completeFigures.map(itm =>
    Object.assign({}, itm, {
      likeCount: allFigureLikes.filter(
        item => item.completeFigureLikesId === itm.id
      ).length
    })
  )

  return (
    <>
      <div
        className={`o96-body o96-body--with-centered-footer ${
          isMobile ? 'o96-body--mobile' : ''
        }`}
      >
        <Container
          sx={{
            paddingTop: '2.25rem'
          }}
        >
          <Box
            className='o96-box--main'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pb: '2rem'
            }}
          >
            <AppBar
              position='static'
              sx={{
                borderRadius: '.4875rem .4875rem 0 0',
                display: 'flex',
                alignItems: 'center',
                padding: '.75rem 0'
              }}
            >
              <Typography variant={isMobile ? 'h6' : 'h5'} component='div'>
                Most Liked (<FavoriteOutlinedIcon fontSize='small' />
                'd) Figures
              </Typography>
            </AppBar>
            {likesStatus === 'fetching' && (
              <Loader loaderText='Loading Figures with Accessories...' />
            )}
            {likesStatus === 'fetched' && (
              <HorizontalBarChart allFiguresWithLikes={allFiguresWithLikes} />
            )}
          </Box>
        </Container>
      </div>
      <div style={{ padding: '.5rem 0 .5rem', width: '100%' }}>
        <Footer theme={`centered`} />
      </div>
    </>
  )
}
export default ChartsPage
