import React, { useState, useEffect, useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { updateFigure } from '../../graphql/mutations'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

const CollectionEditFigure = ({
  rowParamsFigure,
  wasSubmitted,
  setFigureHasEdited
}) => {
  const [figureCondition, setFigureCondition] = useState(
    rowParamsFigure?.row.condition
  )
  const [figureMaker, setFigureMaker] = useState(rowParamsFigure?.row.maker)
  const [figureNote, setFigureNote] = useState(rowParamsFigure?.row.note)
  const initFigure = {
    condition: rowParamsFigure?.row.condition,
    maker: rowParamsFigure?.row.maker,
    note: rowParamsFigure?.row.note
  }

  const sliderMarks = [
    { value: 0 },
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
    { value: 50 },
    { value: 60 },
    { value: 70 },
    { value: 75 },
    { value: 80 },
    { value: 85 },
    { value: 90 },
    { value: 95 },
    { value: 100 }
  ]

  useEffect(() => {
    setFigureHasEdited(false)
  }, [wasSubmitted, setFigureHasEdited])

  function conditionValue (value) {
    return `${value}`
  }

  function conditionValueLabelFormat (value) {
    return value === 0 ? 'None Specified' : `${value}`
  }

  const handleFigureCondition = e => {
    setFigureCondition(e.target.value)
  }

  const handleFigureMaker = e => {
    if (figureMaker === e.target.value) {
      setFigureMaker('N/A')
    } else {
      setFigureMaker(e.target.value)
    }
  }

  const handleFigureNote = e => {
    if (e.target.value === '') {
      setFigureNote()
    }
    setFigureNote(e.target.value)
  }

  const initCurrentCheck = (initProp, currentProp) => {
    if (currentProp && initProp !== currentProp) {
      return true
    } else if (currentProp === 0 && currentProp !== initProp) {
      return true
    }
  }

  const anyDiffs = useCallback(() => {
    if (initFigure.condition !== figureCondition) {
      return true
    } else if (initFigure.maker !== figureMaker) {
      return true
    } else if (initFigure.note !== figureNote) {
      return true
    } else {
      return false
    }
  }, [
    figureCondition,
    figureMaker,
    figureNote,
    initFigure.condition,
    initFigure.maker,
    initFigure.note
  ])

  const figUpdate = async () => {
    try {
      const figureInput = {
        ...(initCurrentCheck(initFigure.condition, figureCondition) && {
          condition: figureCondition
        }: {}),
        ...(initCurrentCheck(initFigure.maker, figureMaker) && {
          maker: figureMaker
        }: {}),
        ...(initCurrentCheck(initFigure.note, figureNote) && {
          note: figureNote
        }: {}),
        ...(!figureNote && {
          note: null
        })
      }

      await API.graphql(
        graphqlOperation(updateFigure, {
          input: { id: rowParamsFigure.id, ...figureInput }
        })
      )

      setFigureHasEdited(true)
    } catch (e) {
      console.log(e)
    }
  }

  if (wasSubmitted && anyDiffs()) {
    figUpdate()
  }

  useEffect(() => {
    if (wasSubmitted && !anyDiffs()) {
      setFigureHasEdited(true)
    }
  }, [wasSubmitted, anyDiffs, setFigureHasEdited])

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        direction='row'
        alignItems='center'
        sx={{ mb: 1 }}
      >
        <Grid item xs={4} md={2} textAlign={{ xs: 'left', md: 'right' }}>
          <Typography variant='boldLabel'>Condition:</Typography>
        </Grid>

        <Grid item xs={8} md={10} sx={{ mt: '7px' }}>
          <Slider
            key={`slider-${conditionValue}`}
            aria-label='Condition values'
            getAriaValueText={conditionValue}
            value={figureCondition}
            valueLabelFormat={conditionValueLabelFormat}
            valueLabelDisplay='on'
            onChange={handleFigureCondition}
            step={null}
            min={0}
            marks={sliderMarks}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems='center' sx={{ mb: 4 }}>
        <Grid item xs={2} md={2} textAlign={{ xs: 'left', md: 'right' }}>
          <Typography variant='boldLabel'>Type:</Typography>
        </Grid>
        <Grid item xs={10} md={4} textAlign={{ xs: 'right', md: 'left' }}>
          <ToggleButtonGroup
            value={figureMaker}
            color='primary'
            size='small'
            exclusive
            onChange={handleFigureMaker}
            aria-label='Maker of Action Figure'
          >
            <ToggleButton value='vintageKenner' aria-label='Vintage Kenner'>
              Vintage Kenner
            </ToggleButton>
            <ToggleButton value='custom' aria-label='Custom'>
              Custom
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
          textAlign={{ xs: 'left', md: 'right' }}
          justifyContent='flex-start'
        >
          <Typography variant='boldLabel'>Note:</Typography>
        </Grid>
        <Grid item xs={10} md={4}>
          <TextField
            id='outlined-multiline-static'
            value={figureNote ? figureNote : ''}
            onChange={handleFigureNote}
            label='Note'
            multiline
            size='large'
            rows={2}
            fullWidth
            inputProps={{ maxLength: 150 }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CollectionEditFigure
