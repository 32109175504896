import React, { useState } from 'react'
import { Link as RouterLink, Navigate, useLocation } from 'react-router-dom'

import { Auth } from 'aws-amplify'

import ConfirmAccountForm from '../../components/ConfirmAccountForm/ConfirmAccountForm'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { alpha } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import useMediaQuery from '@mui/material/useMediaQuery'

import Textfield from '../../components/Forms/Textfield'

import useBodyClass from '../../hooks/useBodyClass'
import { useUser } from '../../hooks/useUser'

import { Footer } from '../../components/Footer'

const initialFormValues = { email: '', password: '' }
const formValidation = Yup.object().shape({
  email: Yup.string().required('Email is Required'),
  password: Yup.string().required('Password is Required')
})

const CreateAccountPage = () => {
  useBodyClass('o96-bg--space')
  const [confirm, setConfirm] = useState(false)
  const [passedEmail, setPassedEmail] = useState('')
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { user } = useUser()
  const location = useLocation()

  const mostRecentFrom = location.state?.from

  if (user?.username) {
    return <Navigate to='/' replace />
  }

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await Auth.signUp(values.email, values.password)
      setSubmitting(false)
      setPassedEmail(values.email)
      setConfirm(true)
    } catch (e) {
      console.log(e)
      if (e.code === 'UsernameExistsException') {
        setErrors({ email: e.message })
      } else if (
        e.code === 'InvalidParameterException' ||
        e.code === 'InvalidPasswordException'
      ) {
        setErrors({
          password: 'Sorry, your password must be at least 8 characters long'
        })
      }
      setSubmitting(false)
    }
  }

  return (
    <>
      <div
        className={`o96-body o96-body--with-centered-footer ${
          isMobile ? 'o96-body--mobile' : ''
        }`}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              mt: 5,
              px: 4,
              pt: 2,
              pb: 4,
              backgroundColor: theme =>
                alpha(theme.palette.background.default, 0.9),
              border: 1,
              borderRadius: 2,
              borderColor: theme => theme.palette.divider,
              maxWidth: 550
            }}
          >
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              align='center'
              sx={{
                mb: { xs: 3, md: 1 }
              }}
            >
              {!confirm ? 'Create Account' : 'Confirm Account'}
            </Typography>
            {!isMobile ? (
              <Typography
                variant='body2'
                align='center'
                sx={{
                  px: 3,
                  mb: 3
                }}
              >
                Manage your own collection, participate in surveys, and checkout
                charts and graphs
              </Typography>
            ) : (
              ''
            )}
            {!confirm ? (
              <>
                <Formik
                  initialValues={initialFormValues}
                  validationSchema={formValidation}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, values, isValid }) => (
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Textfield name='email' type='email' label='Email' />
                        </Grid>
                        <Grid item xs={12}>
                          <Textfield
                            name='password'
                            type='password'
                            label='Password'
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            type='submit'
                            size='large'
                            disabled={isSubmitting || !isValid}
                            variant='contained'
                            sx={{ mb: { xs: 2, md: 0 } }}
                          >
                            Create Account
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
                <Typography variant='body2' align='center' sx={{ mt: 2 }}>
                  Already have an account?{' '}
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: '/sign-in'
                    }}
                    state={{ from: mostRecentFrom }}
                  >
                    Sign in
                  </Link>
                </Typography>
              </>
            ) : (
              <ConfirmAccountForm passedEmail={passedEmail} />
            )}
          </Box>
        </Container>
      </div>
      <Footer theme={`centered`} />
    </>
  )
}

export default CreateAccountPage
