import React, { useState, useCallback, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import {
  createAccessory,
  updateAccessory,
  deleteAccessory
} from '../../graphql/mutations'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import LaunchIcon from '@mui/icons-material/Launch'
import Button from '@mui/material/Button'
import { DataGridPro, GridEditInputCell } from '@mui/x-data-grid-pro'

const StyledStack = styled(Stack)(({ theme }) => ({
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${
      theme.palette.mode === 'dark' ? 0 : 0.1
    })`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f'
  }
}))

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}))

const CollectionEditAccessory = ({
  rowParamsFigure,
  collection,
  figureHasEdited,
  setFigureHasEdited,
  setFetchLatestCollection,
  handleCloseEdit,
  allAccessories,
  ownedAccessories,
  setStatus
}) => {
  const collectionId = collection?.id
  const [initSelectionModel, setInitSelectionModel] = useState()
  const [selectionModel, setSelectionModel] = useState()
  const [accessoryInventory, setAccessoryInventory] = useState([])
  const accessoriesToBeAdded = selectionModel?.filter(
    x => !initSelectionModel.includes(x)
  )
  const accessoriesToAdd = accessoryInventory?.filter(x =>
    accessoriesToBeAdded.includes(x.id)
  )
  const accessoriesToDelete = initSelectionModel?.filter(
    x => !selectionModel.includes(x)
  )
  const accessoryMakerToUpdate = accessoryInventory?.filter(x =>
    ownedAccessories?.find(y => y.id === x.id && y.maker !== x.maker)
  )
  const accessoryNoteToUpdate = accessoryInventory?.filter(x =>
    ownedAccessories?.find(y => y.id === x.id && y.note !== x.note)
  )

  useEffect(() => {
    setFigureHasEdited(false)
  }, [figureHasEdited, setFigureHasEdited])

  useEffect(() => {
    const seletedAccessoryIds = ownedAccessories.map(r => r.id)
    setSelectionModel(seletedAccessoryIds)
    setInitSelectionModel(seletedAccessoryIds)
  }, [ownedAccessories])

  useEffect(() => {
    const ownedKeyNames = new Set(
      ownedAccessories.map(({ keyName }) => keyName)
    )
    setAccessoryInventory([
      ...allAccessories.filter(({ keyName }) => !ownedKeyNames.has(keyName)),
      ...ownedAccessories
    ])
  }, [allAccessories, ownedAccessories])

  const handleSelectionModelChange = useCallback(newSelections => {
    setSelectionModel(newSelections)
  }, [])

  function validateNote (noteValue) {
    return new Promise(resolve => {
      resolve(noteValue?.length > 150 ? true : false)
    })
  }

  const preProcessEditCellProps = async params => {
    const showHideTip = await validateNote(params.props.value)
    const newArray = accessoryInventory.map(r => {
      if (r.id === params.id && !showHideTip) {
        return { ...r, note: params.props.value }
      } else {
        return { ...r }
      }
    })
    setAccessoryInventory(newArray)
    return { ...params.props, error: showHideTip }
  }

  const NoteEditInputCell = React.forwardRef((props, ref) => {
    return (
      <StyledTooltip
        open={props.error ? props.error : false}
        title='Notes are limited to 150 characters'
      >
        <span>
          <GridEditInputCell {...props} ref={ref} />
        </span>
      </StyledTooltip>
    )
  })

  function renderEditNote (params) {
    return <NoteEditInputCell {...params} />
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Accessory',
      width: 200,
      disableSelectionOnClick: true
    },
    {
      field: 'maker',
      headerName: 'Type',
      renderCell: params => {
        if (selectionModel.find(x => x === params.row.id)) {
          return (
            <ToggleButtonGroup
              value={params.row.maker}
              color='primary'
              size='small'
              onChange={e => {
                const newArray = accessoryInventory.map(r => {
                  if (r.id === params.id && r.maker === e.target.value) {
                    return { ...r, maker: null }
                  } else if (r.id === params.id) {
                    return { ...r, maker: e.target.value }
                  } else {
                    return { ...r }
                  }
                })
                setAccessoryInventory(newArray)
              }}
              exclusive
              aria-label='Type of the Accessory'
            >
              <ToggleButton value='vintageKenner' aria-label='Vintage Kenner'>
                Vintage Kenner
              </ToggleButton>
              <ToggleButton value='custom' aria-label='Custom'>
                Custom
              </ToggleButton>
            </ToggleButtonGroup>
          )
        } else {
          return (
            <Button
              sx={{ my: 2 }}
              variant='contained'
              href={params.row.ebayLink}
              rel='noreferrer'
              target='_blank'
              size='small'
              color='primary'
              endIcon={<LaunchIcon />}
            >
              Find on eBay
            </Button>
          )
        }
      },
      width: 250
    },
    {
      field: 'note',
      headerName: 'Note',
      editable: true,
      preProcessEditCellProps,
      renderEditCell: renderEditNote,
      width: 350
    }
  ]

  const accessoryDelete = async () => {
    try {
      await Promise.all(
        accessoriesToDelete.map(accessoryId =>
          API.graphql(
            graphqlOperation(deleteAccessory, {
              input: { id: accessoryId }
            })
          )
        )
      )
    } catch (e) {
      console.log(e)
    }
  }

  const accessoryAdd = async () => {
    try {
      await Promise.all(
        accessoriesToAdd.map(x => {
          const accessoryInput = {
            keyName: x.keyName,
            name: x.name,
            maker: x.maker,
            note: x.note,
            collectionAccessoriesId: collectionId,
            figureAccessoriesId: rowParamsFigure.id
          }
          API.graphql(
            graphqlOperation(createAccessory, {
              input: accessoryInput
            })
          )
          return console.log('Added accessory')
        })
      )
    } catch (e) {
      console.log(e)
    }
  }

  const accessoryMakerUpdate = async () => {
    try {
      await Promise.all(
        accessoryMakerToUpdate.map(x => {
          const accessoryInput = {
            id: x.id,
            maker: x.maker
          }
          API.graphql(
            graphqlOperation(updateAccessory, {
              input: accessoryInput
            })
          )
          return console.log('Updated maker')
        })
      )
    } catch (e) {
      console.log(e)
    }
  }

  const accessoryNoteUpdate = async () => {
    try {
      await Promise.all(
        accessoryNoteToUpdate.map(x => {
          const accessoryInput = {
            id: x.id,
            note: x.note
          }
          API.graphql(
            graphqlOperation(updateAccessory, {
              input: accessoryInput
            })
          )
          return console.log('Updated note')
        })
      )
    } catch (e) {
      console.log(e)
    }
  }

  const runSubmit = async () => {
    await accessoryDelete()
    await accessoryAdd()
    await accessoryMakerUpdate()
    await accessoryNoteUpdate()
    await setFetchLatestCollection(x => !x)
    setStatus('fetching')
    await handleCloseEdit()
  }

  if (figureHasEdited) {
    runSubmit()
  }

  return (
    <>
      {rowParamsFigure?.row.accessories !== null && (
        <Grid container spacing={1} alignItems='flex-start' sx={{ mt: -2 }}>
          <Grid item xs={12} md={2} textAlign={{ xs: 'left', md: 'right' }}>
            <Typography variant='boldLabel'>Accessories:</Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Paper sx={{ flex: 1, mx: 'auto', width: '100%', p: 1 }}>
              <StyledStack direction='column' sx={{ height: '210px' }}>
                <DataGridPro
                  density='comfortable'
                  columns={columns}
                  rows={accessoryInventory}
                  checkboxSelection
                  hideFooter
                  headerHeight={36}
                  selectionModel={selectionModel}
                  onSelectionModelChange={handleSelectionModelChange}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </StyledStack>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default CollectionEditAccessory
