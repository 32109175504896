import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import { Header } from './components/Header'
import { HomePage } from './pages/HomePage'
// import { AboutPage } from './pages/AboutPage'
import { ChartsPage } from './pages/ChartsPage'
import { FigureHome } from './components/FigureHome'
import { FiguresPage } from './pages/FiguresPage'
import { CollectionPage } from './pages/CollectionPage'
import { CollectionGridFigures } from './components/CollectionGridFigures'
import { CollectionGridAccessories } from './components/CollectionGridAccessories'
import { CollectionAddBulk } from './components/CollectionAddBulk'
import { SignInPage } from './pages/SignInPage'
import { CreateAccountPage } from './pages/CreateAccountPage'
import { ConfirmAccountPage } from './pages/ConfirmAccountPage'
import { ForgotPasswordPage } from './pages/ForgotPasswordPage'
import { TermsPage } from './pages/TermsPage'
import { PrivacyPage } from './pages/PrivacyPage'
import { DisclaimerPage } from './pages/DisclaimerPage'
import { SettingsPage } from './pages/SettingsPage'
import CookieConsent from 'react-cookie-consent'

function App () {
  const cookieConsentRef = React.useRef()
  return (
    <>
      <Header />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path='/' element={<HomePage />} />
        <Route path='figures' element={<FigureHome />} />
        <Route path='figures/:figureId' element={<FiguresPage />} />
        <Route path='charts' element={<ChartsPage />} />
        <Route path='collection' element={<CollectionPage />}>
          <Route index element={<>Placeholder</>} />
          <Route
            path='figures-with-accessories'
            element={<CollectionGridFigures />}
          />
          <Route
            path='loose-accessories'
            element={<CollectionGridAccessories />}
          />
        </Route>
        <Route path='add-bulk' element={<CollectionAddBulk />} />
        {/* <Route path='about' element={<AboutPage />} /> */}
        <Route path='sign-in' element={<SignInPage />} />
        <Route path='create-account' element={<CreateAccountPage />} />
        <Route path='confirm-account' element={<ConfirmAccountPage />} />
        <Route path='forgot-password' element={<ForgotPasswordPage />} />
        <Route path='terms-and-conditions' element={<TermsPage />} />
        <Route path='privacy-policy' element={<PrivacyPage />} />
        <Route path='disclaimer' element={<DisclaimerPage />} />
        <Route path='settings' element={<SettingsPage />} />
        <Route
          path='*'
          element={
            <p>
              <br />
              <br />
              <br />
              <br />
              <br />
              There's nothing here: 404!
            </p>
          }
        />
      </Routes>
      <CookieConsent
        location='bottom'
        buttonText='I Accept'
        cookieName='o96CookieBanner'
        expires={365}
        ref={cookieConsentRef}
        containerClasses='o96-banner'
        contentClasses='o96-banner__content'
        buttonStyle={{ display: 'none' }}
      >
        <Grid container alignItems='center' spacing={2}>
          <Grid item xs={12} md={6}>
            <span style={{ fontSize: '14px' }}>
              This website uses cookies to enhance the user experience.
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              href='/terms-and-conditions'
              variant='default'
              sx={{ marginRight: 2 }}
            >
              Terms and Conditions
            </Button>
            <Button
              variant='outlined'
              onClick={() => cookieConsentRef.current.accept()}
            >
              I Accept
            </Button>
          </Grid>
        </Grid>
      </CookieConsent>
    </>
  )
}

export default App
