import React, { useRef, useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createLike } from '../../graphql/mutations'
import { deleteLike } from '../../graphql/mutations'
import { listLikes } from '../../graphql/queries'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import { fetchWithAuthMode } from '../../utils'
import Tridi from 'react-tridi'
import 'react-tridi/dist/index.css'

import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Footer } from '../../components/Footer'
import { PillButton } from '../../components/PillButton'

const Figure = ({ completeFigures }) => {
  const { user } = useUser()
  const { figureId } = useParams()
  const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false)
  const tridiRef = useRef(null)
  const currentFigure = completeFigures.find(x => x.linkPath === figureId)
  const currentItemIndex = completeFigures.findIndex(
    x => x.keyName === currentFigure.keyName
  )
  const location = useLocation()
  const navigate = useNavigate()
  const [figureLikes, setFigureLikes] = useState([])
  const [liked, setLiked] = useState(false)
  const likedThisFigure = figureLikes.find(x => x.owner === user?.username)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const fetchLikes = async mode => {
    const likesData = await API.graphql({
      query: listLikes,
      authMode: mode
    })
    const allLikesArray = likesData.data.listLikes.items
    const currentFigureLikes = allLikesArray.filter(
      x => x.completeFigureLikesId === currentFigure?.id
    )
    setFigureLikes(currentFigureLikes)
  }

  useEffect(() => {
    fetchWithAuthMode(fetchLikes)
  }, [currentFigure]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLiked(likedThisFigure)
  }, [likedThisFigure])

  const toggleLike = async () => {
    try {
      if (!liked) {
        const userLike = {
          email: user.attributes.email,
          completeFigureLikesId: currentFigure.id
        }
        const figureLike = await API.graphql(
          graphqlOperation(createLike, { input: userLike })
        )
        setFigureLikes([...figureLikes, figureLike.data.createLike])
        setLiked(true)
      } else {
        const likeId = {
          id: likedThisFigure.id
        }
        await API.graphql(graphqlOperation(deleteLike, { input: likeId }))
        fetchWithAuthMode(fetchLikes)
      }
    } catch (e) {
      console.log(e)
      if (user === 'unauthenticated') {
        navigate('/sign-in', { state: { from: location.pathname } })
      }
    }
  }

  return (
    <main
      className={`o96-main o96-main--space ${
        isMobile ? 'o96-main--with-bottom-well' : ''
      }`}
    >
      <div
        className={`o96-figure__container ${
          isMobile ? 'o96-figure__container--mobile' : ''
        }`}
      >
        <div className='o96-figure__border--outer'>
          <div className='o96-figure__border--inner'>
            <div className='o96-figure__border--outer2'>
              <div className='o96-figure__border--inner2'>
                <div
                  className={'o96-figure__frame'}
                  style={{ background: currentFigure?.hexColor }}
                >
                  <div className='o96-figure__frame-header'>
                    <Typography
                      variant='body2'
                      component='span'
                      sx={{
                        color:
                          currentFigure?.textColor === 'black' ? '#000' : '#FFF'
                      }}
                    >
                      {figureLikes === 0 ? '' : figureLikes?.length}
                    </Typography>
                    <IconButton
                      onClick={() => toggleLike(currentItemIndex)}
                      sx={{ margin: '0 .75rem 0 .25rem' }}
                      aria-label={'Like this ' + currentFigure?.name}
                      color={
                        currentFigure?.textColor === 'black' ? 'black' : 'white'
                      }
                    >
                      {liked ? (
                        <FavoriteOutlinedIcon />
                      ) : (
                        <FavoriteBorderOutlinedIcon />
                      )}
                    </IconButton>
                  </div>
                  <Tridi
                    ref={tridiRef}
                    location={'/img/figures/' + currentFigure?.linkPath}
                    format='png'
                    count='24'
                  />
                  <div className='o96-figure__360-controls'>
                    <IconButton
                      aria-label='back one frame'
                      onClick={() => tridiRef.current.prev()}
                      color={
                        currentFigure?.textColor === 'black' ? 'black' : 'white'
                      }
                    >
                      <ArrowBackIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setIsAutoPlayRunning(isAutoPlayRunning ? false : true)
                        tridiRef.current.toggleAutoplay(!isAutoPlayRunning)
                      }}
                      aria-label={
                        isAutoPlayRunning
                          ? 'pause spin animation'
                          : 'play spin animation'
                      }
                      color={
                        currentFigure?.textColor === 'black' ? 'black' : 'white'
                      }
                    >
                      {isAutoPlayRunning ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <IconButton
                      aria-label='forward one frame'
                      onClick={() => tridiRef.current.next()}
                      color={
                        currentFigure?.textColor === 'black' ? 'black' : 'white'
                      }
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMobile && (
          <Button
            sx={{ mt: 2 }}
            href='/'
            variant='outlined'
            size='small'
            startIcon={<ArrowLeftIcon size='xs' />}
          >
            All Figures
          </Button>
        )}
      </div>
      {isMobile && (
        <>
          <div className='o96-mobile-button-well'>
            <PillButton
              currentFigure={currentFigure}
              buttonPath={currentFigure?.ebayLink}
              buttonText={`Find ${currentFigure?.shortName} on eBay`}
            />
          </div>
        </>
      )}
      <div style={{ padding: '0 0 .5rem', width: '100%' }}>
        <Footer theme={`centered`} />
      </div>
    </main>
  )
}

export default Figure
