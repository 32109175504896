import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import BarChartIcon from '@mui/icons-material/BarChart'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import LogoutIcon from '@mui/icons-material/Logout'
import LoginIcon from '@mui/icons-material/Login'
import AddBoxIcon from '@mui/icons-material/AddBox'
import SettingsIcon from '@mui/icons-material/Settings'
import { useUser } from '../../hooks/useUser'

const DrawerMenu = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const { logout, user } = useUser()
  const location = useLocation()

  const handleLogout = () => {
    logout()
    setOpenDrawer(false)
  }

  return (
    <>
      <Drawer
        anchor='right'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        className='theDrawer'
      >
        <List>
          <ListItemButton
            component={Link}
            to={{
              pathname: '/figures'
            }}
            state={{ from: location.pathname }}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary='Figures' />
          </ListItemButton>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to={{
                pathname: '/collection'
              }}
              state={{ from: location.pathname }}
              onClick={() => setOpenDrawer(false)}
            >
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary='Collection' />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItemButton
            component={Link}
            to={{
              pathname: '/charts'
            }}
            state={{ from: location.pathname }}
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary='Charts' />
          </ListItemButton>

          {user === 'unauthenticated' ? (
            <>
              {' '}
              <Divider />
              <ListItemButton
                component={Link}
                to={{
                  pathname: '/sign-in'
                }}
                state={{ from: location.pathname }}
                onClick={() => setOpenDrawer(false)}
              >
                <ListItemIcon>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText primary='Sign In' />
              </ListItemButton>
              <Divider />
              <ListItemButton
                component={Link}
                to={{
                  pathname: '/create-account'
                }}
                state={{ from: location.pathname }}
                onClick={() => setOpenDrawer(false)}
              >
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary='Create Account' />
              </ListItemButton>
            </>
          ) : (
            <>
              <Divider />
              <ListItemButton
                component={Link}
                to={{
                  pathname: '/settings'
                }}
                state={{ from: location.pathname }}
                onClick={() => setOpenDrawer(false)}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary='Settings' />
              </ListItemButton>
              <Divider />
              <ListItemButton
                component={Link}
                to={{
                  pathname: '/'
                }}
                state={{ from: location.pathname }}
                onClick={handleLogout}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary='Sign Out' />
              </ListItemButton>
            </>
          )}
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </>
  )
}

export default DrawerMenu
