import { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { listCompleteFigures } from '../graphql/queries'
import { fetchWithAuthMode } from '../utils'

export const useGetFigures = figureRefetch => {
  const [figureStatus, setFigureStatus] = useState('idle')
  const [completeFigures, setCompleteFigures] = useState([])

  function dynamicSort (property) {
    var sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }
    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property])
      } else {
        return a[property].localeCompare(b[property])
      }
    }
  }

  useEffect(() => {
    const fetchFigures = async mode => {
      try {
        setFigureStatus('fetching')
        const completeFigureData = await API.graphql({
          query: listCompleteFigures,
          authMode: mode
        })
        const completeFigureList = await completeFigureData.data
          .listCompleteFigures.items
        const completeFigureListAlpha = await completeFigureList.sort(
          dynamicSort('name')
        )
        setCompleteFigures(
          completeFigureListAlpha.filter(x => x.currentRelease)
        )
        setFigureStatus('fetched')
      } catch (e) {
        console.log(e)
      }
    }
    fetchWithAuthMode(fetchFigures)
  }, [figureRefetch])

  return { figureStatus, completeFigures }
}
